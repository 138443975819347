<template>
  <div class="cesium-toolbar">
    <div v-show="isShow" class="jcsb">
      <!-- <div class="jcsb"> -->

      <div class="toolbar_box" @click="handleClick">
        <el-tooltip v-if="mapStatus" content="夜景图" placement="top" effect="light">
          <img  src="./img/yj.svg"  @click="toggle(3)" />
        </el-tooltip>
        <el-tooltip v-else content="卫星图" placement="top" effect="light">
          <img  src="./img/sj.svg" @click="toggle(4)" />
        </el-tooltip>
      </div>

      <div class="toolbar_box" @click="() => $emit('toolbar-click', 1)">
        <el-tooltip content="飞入原点" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_set_origin.png" />
        </el-tooltip>
      </div>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 2)">
        <el-tooltip content="设置原点" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_get_origin.png" />
        </el-tooltip>
      </div>
      <el-popover placement="bottom" width="120" trigger="hover" popper-class="el-popover-box">
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 3)">
            <!-- <el-tooltip content="水平测量" placement="top" effect="light"> -->
            <img src="~@/assets/basic-tools/icon_horizontal.png" />
            <!-- </el-tooltip> -->
            <span>水平测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 4)">
            <!-- <el-tooltip content="垂直测量" placement="top" effect="light"> -->
            <img src="~@/assets/basic-tools/icon_vertical.png" />
            <!-- </el-tooltip> -->
            <span>垂直测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 5)">
            <!-- <el-tooltip content="面积测量" placement="top" effect="light"> -->
            <img src="~@/assets/basic-tools/icon_area.png" />
            <!-- </el-tooltip> -->
            <span>面积测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 6)">
            <!-- <el-tooltip content="体积测量" placement="top" effect="light"> -->
            <img src="~@/assets/basic-tools/icon_volume.png" />
            <!-- </el-tooltip> -->
            <span>体积测量</span>
          </div>
        </div>
        <div slot="reference" class="toolbar_box">
          <el-tooltip content="测量工具" placement="top" effect="light">
            <img src="~@/assets/basic-tools/icon_measure.svg" />
          </el-tooltip>
        </div>
      </el-popover>
      <el-popover placement="bottom" width="120" trigger="hover" popper-class="el-popover-box">
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 7)">
            <img src="~@/assets/basic-tools/icon_topsight.svg" />
            <span>顶视</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 8)">
            <img src="~@/assets/basic-tools/icon_overlook.svg" />
            <span>俯视</span>
          </div>
        </div>
        <div slot="reference" class="toolbar_box">
          <el-tooltip content="视图" placement="top" effect="light">
            <img src="~@/assets/basic-tools/icon_view.png" />
          </el-tooltip>
        </div>
      </el-popover>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 9)">
        <el-tooltip content="指北" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_north.png" />
        </el-tooltip>
      </div>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 10)">
        <el-tooltip content="人视角" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_perspective.png" />
        </el-tooltip>
      </div>

      <div class="toolbar_box" @click="handleFullscreen">
        <el-tooltip v-if="!fullscreen" content="全屏" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_fullscreen.png" />
        </el-tooltip>
        <el-tooltip v-else content="退出全屏" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_smallscreen.png" />
        </el-tooltip>
      </div>
    </div>
    <!-- toolbar_box -->
    <div class="w50 h50 " @click="isShow = !isShow">
      <el-tooltip content="工具栏" placement="left" effect="dark">
        <img class="w50 h50 cp" style="transform: scale(1.3);" src="./1.png" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { DOM } from "@/utils/dom.js";
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      fullscreen: false,
      isShow: false,
      collapse: false,
      command: false,
      notFlyIsShow: true,
      mapStatus: false
    };
  },
  watch: {
    "$store.state.device.flag"(newData) {
      this.collapse = newData;
    }, 
  },
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  created() {
  
    if (this.$route.path == "/command") {
      this.command = true;
    } else {
      this.command = false;
    }
    if(this.my_layers_data=='tian'){
      this.mapStatus=false
    }else{
      this.mapStatus=true

    }
  },
  mounted() {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".cesium-toolbar"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isShow = false
      }
    });
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP
    if (this.my_layers_data === 'arcGIS1') {
    } else if (this.my_layers_data === 'arcGIS') {
    } else if (this.my_layers_data === 'night') {
      this.mapStatus = false
    } else if (this.my_layers_data === 'tian') {
      this.mapStatus = true

    }
  },
  methods: {
    ...mapActions("user", ["Mylayers"]),
    handleClick() {
      this.notFlyIsShow = !this.notFlyIsShow;
      this.$emit("toolbar-click", 11, this.notFlyIsShow);
    },
    toggle(data) {
      if (data === 3) {
        this.Mylayers('night')
        this.mapStatus = false

      } else if (data === 4) {
        this.Mylayers('tian')
        this.mapStatus = true

      }
      this.show = false
    },
    handleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        DOM.fullscreen();
      } else {
        DOM.exit_fullscreen();
      }
    },
    moveToolbar(e) {
      if (e == "center") {
        this.collapse = false;
      } else if (e == "left") {
        this.collapse = true;
      } else {
        this.collapse = !this.collapse;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cesium-toolbar {
  // position: absolute;
  // left: 550px;
  // top: 102px;

  position: absolute;
  right: 0;
  top: 80px;
  display: flex;
  transform: translateX(0);
  transition: 0.3s;

  .jcsb {
    display: flex;
    justify-content: space-between;
  }

  .toolbar_box {
    width: 50px;
    height: 50px;
    border: 1px solid #007aff;
    background: rgba(6, 25, 56, 0.7);
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    margin-left: 1px;

    &:hover {
      opacity: 0.7;
    }

    &>img {
      width: 20px;
      height: 20px;
      margin: 15px auto;
    }
  }

  .hover_box {
    display: flex;
    background: red;

    .toolbar_box {
      img {
        width: 20px;
        height: 20px;
        margin: 15px auto;
      }
    }
  }
}
</style>
<style lang="scss">
.el-popover-box {
  padding: 0;
  background: rgba(0, 0, 0, 0.7) !important;
  min-width: auto;

  .toolbar_box {
    display: flex;
    line-height: 48px;
    color: #cacaca;
    cursor: pointer;
    user-select: none;
    padding-left: 10px;

    &:hover {
      opacity: 0.7;
    }

    &>span {
      margin-left: 10px;
    }

    &>img {
      width: 20px;
      height: 20px;
      margin-top: 15px;
    }
  }
}
</style>
