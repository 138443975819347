<template>
  <div>
    <Dialog title="申请记录" v-if="ApplicationRecord" @on-close="closeApplicationRecord" :width="951">
        <div class="Record">
          <div class="Record_Body">
                <div class="Body_Item" v-for="(item,index) in dataVal" :key="item.id">
                    <div class="Body_Item_Box" :class="ImgFiter(index)">
                      <div class="Item_Box_Top"><span class="Top_Label">数据类型: {{item.type}}</span></div>
                      <div class="Item_Box_Top"><span class="Top_Label">申请时间: {{item.addtime}}</span></div>
                   </div>
                   <div class="Body_Item_Footer">
                        <div class="Footer_Value">
                          <el-tooltip class="item" effect="dark" :content="item.name?item.name:'暂无 '" placement="top-start">
                            <div class="Footer_lable">服务名称: {{item.name?item.name:'暂无'}}</div>
                          </el-tooltip>
                          <div class="Footer_lable">审批状态: {{ApprovalFilter(item.status)}}</div>
                        </div>
                   </div>
                </div>
          </div>
          <div class="Record_Footer">
              <el-pagination class="cpt-table-pagination" @current-change="onChangePage" :current-page="pagination.pageNo || 1"
               layout="total , prev, pager, next, jumper" :total="pagination.total || 0" :page-size="pagination.pageSize || 10" />
          </div>
        </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index.vue'
import API from '@/api'
export default {
  components:{Dialog},
    props:{
        ApplicationRecord:{
            typeof:Boolean,
            default:false
        } 
    },
    data() {
      return {
        pagination: {//分页
          pageNo:1,
          pageSize:6,
          total:0
        },
        dataVal:[],//列表数据
      };
    },
    methods:{
      ImgFiter(index){
        // console.log(index+1 == (3 * (index+1)) - 1);
        // console.log(index+1);
        console.log(index+1 , (3 * (index+1)) - 1);
        index+1 == (3 * (index+1)) - 1?'Box_Img_First':'Box_Img_Second'
      },
      ApprovalFilter(status){//筛选状态并返回
        switch (status) {
          case 0:
            return '待审批'
            break;
          case 1:
            return '同意'
            break;
          case 2:
            return '拒绝'
            break;
        
          default:
            break;
        }
      },
      onChangePage(val){//分页更新数据
        this.pagination.pageNo=val
        this.async_get_list()
      },
      closeApplicationRecord(){
        this.$emit('CloseRecord',false)
      },
     async async_get_list(){
      const { pageNo , pageSize } = this.pagination
      let obj={
          pageNo,
          pageSize
      }
        let res = await API.DEVICE.threeDimensionalList(obj)
           
        if (res.code==200) {
                this.dataVal=res.data.records
                this.pagination.total=res.data.total
            }else{
                this.$message.error(res.message)
            }
      }
    },
    created(){
      this.async_get_list()
    }
}
</script>

<style lang="scss" scoped>
.Record{
  &>.Record_Body{
    display: flex;
    flex-wrap: wrap;
    &>.Body_Item{
      margin-right: 30px;
      margin-bottom: 20px;
      &:nth-child(3n){
        margin-right: 0;
      }
      &:nth-child(3n-1){ //中间的元素显示的图片
        &>.Body_Item_Box{
          background-image: url('~@/assets/img/jilu-second.png');
        }
      }
     &>.Body_Item_Box{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 282px;
      height: 162px;
      background: rgba(196, 212, 255,.4);
      border-radius: 10px;
      backdrop-filter: blur(10px);
      overflow: hidden;
      background-image: url('~@/assets/img/jilu-first.png'); //默认的图片
      &>.Item_Box_Top{
        width: 282px;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        background: rgba(0, 0, 0, .3);
        &>.Top_Label{
          z-index: 2;
          font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #FFFFFF;
        }
      }
    } 
    &>.Body_Item_Footer{
      height: 60px;
      display: flex;
      justify-content: flex-end;
      &>.Footer_Value{
        width: 65%;
        &>.Footer_lable{
          max-width: 150px;
          margin: 8px auto;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          color: #FFFFFF;
           white-space:nowrap;//禁止换行
           overflow:hidden ;//超出隐藏
           text-overflow: ellipsis;//显示省略号
        }
      }
    }
    }
    
  }
  &>.Record_Footer{
    //分页样式
  //列表底部
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .cpt-table-pagination {
    //右下分页组件样式
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep {
      button {
        background: transparent;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        // border: 1px solid #488cff;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border-radius: 4px;
        line-height: 40px;
        margin-right: 8px;
        box-sizing: border-box;
        color: #b6d4ff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:disabled {
          background: transparent;
          color: #b6d4ff;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;

        text-align: center;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin: 0px;
      }

      .el-pager {
      height:30px;

        .number {
          background: transparent;
          font-size: 12px;
          color: #b6d4ff;
          text-align: center;
          font-weight: lighter;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          // border: 1px solid #488cff;
          font-size: 18px;
          letter-spacing: 0;
          text-align: center;
          border-radius: 4px;
          margin-right: 8px;
          box-sizing: border-box;

          &.active {
            opacity: 0.8;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(44, 123, 255, 0.5) 0%,
            //   rgba(72, 140, 255, 0) 100%
            // );
            background-color: #2647ee;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          // line-height: 40px;
          width: 40px;
          // height: 40px;

          box-sizing: border-box;
          // border: 1px solid #629fff;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 20px;
          color: #b6d4ff;
        }
      }

      .el-pagination__jump {
        font-size: 18px;
        color: #b6d4ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        width: 150px;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        display: flex;
        align-items: center;

        .el-input {
          box-sizing: border-box;
          height: 100%;
          width: 84px;

          .el-input__inner {
            width: 60px;
            height: 30px;
            border-radius: 2px;
            border: 1px solid #b6d4ff;
            margin-top: 8px;
            // border: 1px solid #629fff;
            border-radius: 4px;
            background: transparent;
            // height: 100%;
            box-sizing: border-box;
            padding: 0;
            font-size: 18px;
            color: #90b2ec;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    &.mini {
      height: 100%;
      display: flex;
      align-items: center;

      ::v-deep {
        button {
          height: 20px;
          width: 20px;
          line-height: 20px;
          background: transparent;
          border: none;
          color: #4d7592;
          min-width: auto;

          &.btn-next {
            margin-left: 8px;
          }
        }

        .el-pagination__total {
          font-size: 14px;
          color: #4d7592;
        }

        .el-pagination__sizes {
          display: none;
        }

        .el-pager {
          height:30px;


          .number {
            height: 100%;
            width: auto;
            line-height: 20px;
            background: transparent;
            border: none;
            font-size: 14px;
            color: #4d7592;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            min-width: 20px;
            margin-right: 0;

            &.active {
              opacity: 1;
              color: #0099ff;
            }
          }

          .more {
            height: 100%;
            width: 20px;
            line-height: 20px;
            font-size: 12px;
            border: none;
            min-width: auto;
            margin-right: 0px;

            &::before {
              line-height: 20px;
            }
          }
        }

        .el-pagination__jump {
          height: 100%;
          font-size: 14px;
          color: #4d7592;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .el-input {
            .el-input__inner {
              border: 1px solid #4d7592;
              border-radius: 2px;
              width: auto;
              min-width: 20px;
              font-size: 14px;
              color: #4d7592;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  }
}
</style>