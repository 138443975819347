<template>
<!-- :style="contentState=='layout'?'margin-top:20px':''" -->
  <div class="actions-item action-warning"  v-if="contentState!='home'">
          <!-- <el-popover
            placement="bottom"
            trigger="hover"
            popper-class="action-route-popover"
            width="140"
          >
            <div class="action-route-dropdown-list">
              <template v-for="(item, index) in message_list">
                <div
                  class="action-route-dropdown"
                  :class="`${item.path == $route.path ? 'active' : ''}`"
                  :key="index"
                  @click="Notice"
                >
                  <span >{{ item.name }} </span>
                  <i  class="totalValue">{{totalValue(item.name)}}</i>
                </div>
              </template> 

            </div>
            <div slot="reference" class="messageIcon"></div> 
           <div slot="reference" style="text-align:center;color:#fff" class="messageIcon iconfont icon-xiaoxitongzhi"></div>
          <div slot="reference" style="text-align:center;color:#fff" >
            <img src="./img/5.png" class="w53 h53">
          </div>
          </el-popover> -->
          <!-- <div class="text">消息通知</div> -->
          <!-- 红点 -->
          <!-- <i v-if="noticeState" class="text-icon" style="right: 34px;"></i> -->
          <el-tooltip class="item" effect="dark" content="公告消息" placement="bottom">
            <img src="./img/5.png" @click="$emit('opennotice', true);" class="w53 h53" alt="" srcset="">
          </el-tooltip>
  </div>
   <div class="actions-item" v-else>
          <div class="messageIcon"></div>
          <div style="color:#e0e6f2" class="text" :class="contentState=='home'?'innertext':''">消息通知</div>
          <!-- 红点 -->
          <i v-if="noticeState" class="text-icon"></i>
          <div class="dropdown-active">
            <div class="action-route-dropdown-list">
              <template v-for="(item, index) in message_list">
                <div
                  class="action-route-dropdown"
                  :class="`${item.path == $route.path ? 'active' : ''}`"
                  :key="index"
                  
                  @click="Notice"
                >
                  <span >{{ item.name }} </span>
                  <!-- <i class="totalValue" v-if="totalValue(item.name)">{{totalValue(item.name)}}</i> -->
                </div>
              </template>
            </div>
          </div>
        </div>
</template>

<script>
import API from '@/api'
export default {
    props:{
        message_list: {
          type: Array ,
          default: ()=>[],
      },
        contentState: {
          type: String ,
          default: ()=>'',
      },
    },
    data() {
        return {
            message_total_count: {
              warning_total:0,
              demand_total:0,
              approval_total:0
            },
            timerid:null,
            noticeState:false
        };
    },
    methods:{
        // ...mapActions('user',['Message_content']),
        totalValue(value){
      if (value=='预警消息') {
        return this.message_total_count.warning_total
      } else if(value=='需求消息'){
        return this.message_total_count.demand_total
      }else{
        return this.message_total_count.approval_total
      }
    },
    Notice() {//公告弹框
      this.$emit("opennotice", true);
    },
    // async content(){
    // //  let res = await API.TASK.GetNeedsMessageList()
    //  let res = await API.TASK.List({taskStatus:0,pageNum: 1,pageSize: 10,});
    // //  let res1 = await API.AI.GetWarningList()
    //  let res2 = await API.TASK.postapprovalmessage({pageNum: 1,pageSize: 10})
    // //  if (res1.data.length>99) res1.data.length='99⁺'
    //  if (res.data.totalCount>99) res.data.totalCount='99⁺'
    //  if (res2.data.totalCount>99) res2.data.totalCount='99⁺'
    //  this.message_total_count={
    //    demand_total:res.data.totalCount,
    //    approval_total:res2.data.totalCount,
    //   //  warning_total:res1.data.length,
    //   //  demand_total:0,
    //   //  approval_total:0,
    //   //  warning_total:1,
    // }
    // for (const key in this.message_total_count) {
    //   // console.log(this.message_total_count[key]);
    //   if (this.message_total_count[key]) {
    //     //当有数据时显示提示点
    //     this.noticeState=true
    //   }
    // }
    // // if (
    // //   this.message_total_count.demand_total||
    // //   this.message_total_count.approval_total||
    // //   this.message_total_count.warning_total
    // // ) {
    // //   //当有数据时显示提示点
    // //   this.noticeState=true
    // // }
    // //预警,需求,审批消息总数
    //  console.log('获取预警,需求,审批消息');
    //  },
    // Message_content() {
    //  try {
    //  this.content()
    //  //2分钟重新获取一次消息条数
    // this.timerid= setInterval(() => {
    //   this.content()
    //  }, 12e4);
    //  } catch (error) {
    //   this.$message.error('错误:'+error)
    //  }
    // }
    },
    async created(){
      //  await this.Message_content()
    },
    destroyed(){
      //关闭计时器
        clearInterval(this.timerid)
        this.timerid=null
    }
}
</script>

<style lang='scss' scoped>
.actions-item {
        position: relative;
        cursor: pointer;
        // width: 80px;
        height: 50px;
        // background-color: red;
        .safefly-image {
          width: 50px;
          height: 24px;
          background-image: url(~@/assets/platform/security.png);
          background-repeat: no-repeat;
          background-position: center;
        }
               .messageIcon{
             width: 50px;
             height: 24px;
             font-size: 28px;
             color: #5abeff;
            //  background-image: url(~@/assets/platform/message.png) ;
            //  background-repeat: no-repeat;
            //  background-position: center;
           }
        .check-image {
          width: 50px;
          height: 24px;
          background-image: url(~@/assets/platform/inspect.png);
          background-repeat: no-repeat;
          background-position: center;
        }
        .text {
          font-size: 10px;
          color: #9fc4ff;
          width: 76px;
          text-align: left;
        }
        .innertext{
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ecf2ff;
          margin-top: 0px;
          transform: scale(0.833);
          -webkit-transform: scale(0.833);
        }
         &:hover {
          .dropdown-active {
            display: block;
            width: 140px;
            background: rgba(12, 34, 67, 0.5);
            text-align: center;
            // padding: 10px 0;
            min-width: auto;
            box-sizing: border-box;
            border: 1px solid #488cff;
            border-radius: 0;
            position: absolute;
            top: 38px;
            left: -40px;
            z-index: 100000;
            .action-route-dropdown-list {
              .action-route-dropdown {
                position: relative;
                text-align: center;
                font-size: 14px;
                color: #cce8ff;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 30px;
                cursor: pointer;
                &:hover,
                &.active {
                  color: #2cacff;
                }
                .totalValue{
                  position: absolute;
                  right: 25px;
                  top: -3px;
                  color: #ffffff;
                  font-style: normal;
                  display: inline-block;
                  width: 22px;
                  height: 22px;
                  font-size: 12px;
                  transform: scale(70%);
                  background-color: #e1251b;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 22px;
                }
              }
            }
          }
        }
        .text-icon{
          font-style: normal;
          position: absolute;
          right: 7px;
          top: -2px;
          font-size: 12px;
          transform: scale(.8);
          display: block;
          width: 10px;
          height: 10px;
          line-height: 10px;
          border-radius: 50%;
          background-color: #e1251b;
        }
      }
.action-route-popover {
  z-index: 99999999999999;
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-route-dropdown-list {
    .action-route-dropdown {
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      &:hover{
         color: #2cacff;
      }
      &.active {
        color: #2cacff;
      }
      .totalValue{
          position: absolute;
          right: 25px;
          top: -3px;
          color: #ffffff;
          font-style: normal;
          display: inline-block;
          width: 22px;
          height: 22px;
          font-size: 12px;
          transform: scale(70%);
          background-color: #e1251b;
          border-radius: 50%;
          text-align: center;
          line-height: 22px;
        }
    }
  }
}
.iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
  }
</style>