<template>
  <div
    :id="id"
    class="box"
    :style="data.type === 25 ? 'width: 250px' : 'min-width: 250px'"
    :class="data.type === 29 ? 'w260' : ''"
  >
    <div :class="data.type === 25 ? 'pine1' : 'pine'"></div>
    <div
      class="box-wrap"
      :class="data.type === 28 ? 'type-28' : ''"
      :style="data.type === 25 ? 'height: 150px;' : 'height: 153px'"
    >
      <div class="close" @click="closeClick">X</div>
      <div class="area">
        <!-- <div class="area-title fontColor">{{ showType(data.type) }}</div> -->
        <div class="area-title fontColor">{{ data.data.typeName }}</div>
      </div>
      <div class="content">
        <!-- <div class="data-li">
          <div class="data-label textColor">状态：</div>
          <div class="data-value">
            <span class="label-num yellowColor">{{state}}</span>
          </div>
        </div>
        <div class="data-li">
          <div class="data-label textColor">实时水位：</div>
          <div class="data-value">
            <span class="label-num yellowColor">100</span>
            <span class="label-unit textColor">m³/s</span>
          </div>
        </div> -->

        <!-- <div class="data-li">
            <el-table :data="data.data.data" :border="false" style="width: 100%" height="120px">
              <el-table-column prop="deviceName" label="无人机名称" width="90" align="center">
              </el-table-column>
              <el-table-column prop="cateName" label="无人机型号" width="90" align="center">
              </el-table-column>
              <el-table-column prop="state" label="状态" width="50" align="center">
                <template slot-scope="scope">
                  {{ scope.row.state === 1 ? '正常' : '异常' }}
                </template>
              </el-table-column>
              <el-table-column prop="isNet" label="电池数" width="70" align="center">
              </el-table-column>
            </el-table>
          </div> -->

        <div class="Location" v-if="data.type === 25">
          <div class="data-li">
            <div class="data-label textColor">机库布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd }}</span>
            </div>
          </div>

          <div class="pa bottom10 cp right20 cf" v-show="isShow">
            <span
              class="dib mr20"
              v-if="data.data.deviceHardId"
              @click="showVideo_yc(data.data)"
              >视频</span
            ><span @click="toJK">详情</span>
          </div>
          <FloatPlayer
            v-for="item in float_videos"
            :key="item.deviceHardId"
            :id="item.id"
            :title="item.title"
            :selsectAi="item.ai"
            :dataSource="item.dataSource"
            :deviceHardId="item.deviceHardId"
            @on-viewClose="close_video"
            :videoPostion="videoPostion"
          />
        </div>

        <!-- <div class="Location" v-if="data.type === 25">
          <div class="data-li">
            <div class="data-label textColor">无人机布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd||'' }}</span>
            </div>
          </div>
          <div class="data-li">
            <el-table :data="data.data.data" :border="false" style="width: 100%" height="120px">
              <el-table-column prop="deviceName" label="无人机名称" width="90" align="center">
              </el-table-column>
              <el-table-column prop="cateName" label="无人机型号" width="90" align="center">
              </el-table-column>
              <el-table-column prop="state" label="状态" width="50" align="center">
                <template slot-scope="scope">
                  {{ scope.row.state === 1 ? '正常' : '异常' }}
                </template>
              </el-table-column>
              <el-table-column prop="isNet" label="电池数" width="70" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div> -->
        <!-- 基站 -->
        <div v-else-if="data.type === 26">
          <div class="data-li">
            <div class="data-label textColor">基站布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">位置：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.wz || "暂无"
              }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">辐射半径：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.bj || "暂无"
              }}</span>
            </div>
          </div>
        </div>

        <div v-else-if="data.type === 27">
          <div class="data-li">
            <div class="data-label textColor">无人机名称：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.deviceName || "暂无"
              }}</span>
            </div>
          </div>
          <div class="data-li mt20">
            <div class="data-label textColor">无人机型号：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.deviceSerial || "暂无"
              }}</span>
            </div>
          </div>
          <!-- <div class="data-li">
            <div class="data-label textColor">当前飞手：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.fs }}</span>
            </div>
          </div> -->
          <div class="data-li mt20">
            <div class="data-label textColor">当前任务：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.taskTitle || "暂无"
              }}</span>
            </div>
          </div>
          <!-- <div class="data-li">
            <div class="data-label textColor">跳转任务视频：</div>
          </div> -->
        </div>
        <div v-else-if="data.type === 28">
          <div class="data-li">
            <div class="data-label textColor">无人机名称：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.deviceName || "暂无"
              }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">任务名称：</div>
            <div class="data-value">
              <span class="label-num yellowColor">海州街道交通管理</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">任务类型：</div>
            <div class="data-value">
              <span class="label-num yellowColor">交通管理</span>
            </div>
          </div>
          <!-- <div class="data-li">
            <div class="data-label textColor">无人机型号：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.deviceSerial }}</span>
            </div>
          </div> -->
          <div class="data-li" v-if="data.data.online == 1">
            <div class="data-label textColor">当前任务：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{
                data.data.taskTitle
              }}</span>
            </div>
          </div>
          <div class="data-li" v-if="data.data.reportStatus == 1">
            <div class="data-label textColor">
              <el-button size="mini" @click="showVideo(data.data)" round
                >视频</el-button
              >
              <el-button size="mini" @click="doSome(data.data)" round
                >控制</el-button
              >
            </div>
            <FloatPlayer
              v-for="item in float_videos"
              :key="item.deviceHardId"
              :id="item.id"
              :title="item.title"
              :selsectAi="item.ai"
              :dataSource="item.dataSource"
              :deviceHardId="item.deviceHardId"
              @on-viewClose="close_video"
              :videoPostion="videoPostion"
            />
          </div>
        </div>
        <div class="" v-else-if="data.type === 29">
          <div class="data-li">
            <div class="data-label textColor">要素布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd }}</span>
            </div>
          </div>
        </div>
        <div class="" v-else-if="data.type === 119">
          <div class="data-li">
            <div class="data-label textColor">任务名称：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.name }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">任务类型：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.type }}</span>
            </div>
          </div>
          <div class="cf f14" v-if="data.data.url">
            预警图片：
            <!-- <img class="w50 h50" :src="data.data.url" alt=""> -->
            <el-image
              class="w50 h20 dib ml10"
              style="vertical-align: top"
              :src="VUE_APP_WARING + data.data.url"
              :preview-src-list="[VUE_APP_WARING + data.data.url]"
            >
            </el-image>
            <div class="fr"></div>
          </div>
          <div class="jcsb mt2">
            <span class="cf f14">{{ data.data.time }}</span
            ><span
              @click="confirmFn(data.data.id)"
              class="f14"
              style="color: #25c6ea"
              >标记已读</span
            >
          </div>
        </div>
        <div class="Location" v-else>
          <div class="data-li">
            <div class="data-label textColor">要素布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FloatPlayer from "@/pages/platform/visual/view/uav/float-player/index2.vue";
import API from "@/api";
export default {
  components: { FloatPlayer },
  name: "DynamicLabel",
  data() {
    return {
      videoPostion: true,
      show: true,
      float_videos: [],
      isJB: false,
      isShow: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    id: {
      type: String,
      default: "001",
    },
    state: {
      type: String,
      default: "001",
    },
    data: {
      type: Object,
      default: null,
    },
    Read: {
      type: Function,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    toJK() {
      let data = JSON.parse(localStorage.getItem("user_info_hn"));
      if (data.data.departmentId == 60) {
        // 局办
        this.isJB = true;
      } else {
        this.isJB = false;
      }
      let code = null;
      // 是否是局办
      if (this.isJB) {
        code = "1";
      } else {
        code = "2";
      }
      window.open(
        `https://fkzx.hnjcloud.cn/newyingchao/#/?code=${code}`,
        "_blank"
      );
    },
    confirmFn(id) {
      this.$confirm("您是否标记此信息为已读？", "提示", {
        confirmButtonText: "有效",
        cancelButtonText: "无效",
        // showClose:false,
        distinguishCancelAndClose: true,
        type: "warning",
      })
        .then(async () => {
          let res = await API.TASK.setRead({ id });
          if (res.code) {
            this.data.data.isRead = 1;
            await this.Read(id);
          }

          let k = await API.TASK.setEffect({ effect: 1, id });
          this.$message.success("操作成功");
        })
        .catch(async (action) => {
          if (action === "cancel") {
            let res = await API.TASK.setRead({ id });
            if (res.code) {
              this.data.data.isRead = 1;
              await this.Read(id);
            }
            let k = await API.TASK.setEffect({ effect: 0, id });
            this.$message.success("操作成功");
          } else {
          }
        });
    },
    doSome(row) {
      window.open(`/#/new/control?id='${row.id}`, "_search");
    },
    closeClick() {
      if (this.closeEvent) {
        this.closeEvent();
      }
    },
    showType(id) {
      if (id == 25) {
        // return '派出所'
        return "机库";
      } else if (id == 26) {
        return "基站";
      } else if (id == 27) {
        return "无人机";
      } else if (id == 28) {
        return "无人机";
      } else if (id == 29) {
        return "预警提示";
      } else if (id == 31) {
        return "监控";
      }
    },
    async showVideo(row) {
      if (this.float_videos.filter((item) => item.id === row.id).length > 0) {
        this.close_video(row.id);
        // this.$message('已开启视频')
      } else {
        this.float_videos.push({
          ai: row.ai ? row.ai : "",
          id: row.id,
          deviceHardId: row.deviceHardId,
          title: row.deviceName,
          dataSource: row.videoInfo,
        });
      }
    },
    // 鹰巢视频
    async showVideo_yc(row) {
      console.log(row, "row");
      if (row.deviceHardId) {
        if (this.float_videos.filter((item) => item.id === row.id).length > 0) {
          this.close_video(row.id);
        } else {
          let res = await API.ACCIDENT.getMountInfoByDeviceHardId({
            deviceHardId: row.deviceHardId,
          });
          if (res.status == 1) {
            this.float_videos.push({
              ai: row.ai ? row.ai : "",
              id: row.id,
              deviceHardId: row.deviceHardId,
              title: row.deviceName,
              dataSource: res.data.videoInfo,
            });
          }
          console.log(res, "res");
        }
      } else {
        this.$message("暂无视频！");
      }
    },
    close_video(id) {
      // 关闭某个视频后清掉数组中该视频的记录，可以让该视频可以被再次点击
      this.float_videos = this.float_videos.filter((item) => {
        return item.id != id;
      });
    },
  },
  created() {
    const { VUE_APP_WARING } = process.env;
    this.VUE_APP_WARING = VUE_APP_WARING;
    console.log(this.VUE_APP_WARING, "VUE_APP_WARING");
  },
  mounted() {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("cockpit:home:hangar") > -1) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  },
  destroyed() {
    window.this = null;
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 200px;
  position: relative;
  bottom: 0;
  left: 0;

  ::v-deep {
    .pine {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;
        transform-origin: bottom center;
        transform: rotateZ(135deg) scale(1.5);
        animation: slash 0.5s;
        // filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .address_info {
      width: 170px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //用省略号显示
      white-space: nowrap;
    }

    .pine1 {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 56px;
        bottom: -83px;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;
        transform-origin: bottom center;
        transform: rotateZ(135deg) scale(1.5);
        animation: slash 0.5s;
        filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .type-28 {
      width: 140% !important;
    }

    .box-wrap {
      position: absolute;
      left: 2%;
      top: 132px;
      width: 120%;
      height: 163px;
      border-radius: 50px 0px 50px 0px;
      border: 1px solid #38e1ff;
      background-color: #38e1ff4a;
      box-shadow: 0 0 10px 2px #29baf1;

      // background: rgba(0,0,0,0.7);
      .close {
        position: absolute;
        color: #fff;
        top: 1px;
        right: 10px;
        text-shadow: 2px 2px 2px #022122;
        cursor: pointer;
        animation: fontColor 1s;
      }

      .area {
        position: absolute;
        top: 20px;
        right: 0;
        width: 95%;
        height: 30px;
        background-image: linear-gradient(to left, #4cdef9, #4cdef96b);
        border-radius: 30px 0px 0px 0px;
        animation: area 1s;

        &-title {
          text-align: center;
          line-height: 30px;
        }

        .fontColor {
          font-size: 16px;
          font-weight: 800;
          color: #ffffff;
          text-shadow: 1px 1px 5px #002520d2;
          animation: fontColor 1s;
        }
      }

      .textColor {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }

      .yellowColor {
        font-size: 14px;
        font-weight: 600;
        color: #f09e28;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }
    }
  }
}

.content {
  padding: 55px 10px 10px 10px;

  .data-li {
    margin-bottom: 6px;
    display: flex;
  }
}

@keyframes fontColor {
  0% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }

  40% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }

  100% {
    color: #ffffff;
    text-shadow: 1px 1px 5px #002520d2;
  }
}

@keyframes slide {
  0% {
    border: 1px solid #38e1ff00;
    background-color: #38e1ff00;
    box-shadow: 0 0 10px 2px #29baf100;
  }

  100% {
    border: 1px solid #38e1ff00;
    background-color: #38e1ff00;
    box-shadow: 0 0 10px 2px #29baf100;
  }
}

@keyframes area {
  0% {
    width: 0%;
  }

  25% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

@keyframes slash {
  0% {
    transform: rotateZ(135deg) scale(0);
  }

  100% {
    transform: rotateZ(135deg) scale(1.5);
  }
}
</style>
<style lang="scss" scoped>
::v-deep .Location {
  .el-table {
    color: #fff;
  }

  .el-table th.el-table__cell {
    background-color: rgba(0, 0, 0, 0);
  }

  .el-table tr {
    background-color: rgba(0, 0, 0, 0);
  }

  .el-table,
  .el-table__expanded-cell {
    background-color: rgba(0, 0, 0, 0);
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: rgba(0, 0, 0, 0);
  }

  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  .el-table::before {
    height: 0;
  }

  .el-table thead {
    color: #fff;
  }
}
</style>
