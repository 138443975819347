<template>
    <div>
        <Dialog  title="公告" v-if="NoticeShow" @on-close="closeIDsPopup" :width="642">
            <Table 
            :cellName="cellName"
         :dataVal="dataVal" 
          :pagination="pagination"
           @onChangePage="(page)=>{
            pagination.pageNo=page;
            async_get_list()
           }"
           >
           <!-- <template #status="{ row }">
            {{row.item.status == 0 ? '禁用' : '启用'}}
           </template> -->
            </Table>
        </Dialog>
    </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index.vue'
import Table from './table.vue'
import API from '@/api'
export default {
    components:{Dialog,Table},
    props:{
        NoticeShow:{
            typeof:Boolean,
            default:false
        }
    },
    data() {
        return {
            cellName:[
            {value:'title',name:"标题"},
            {value:'content',name:"内容"},
            // {value:'status',name:"状态",slot:true},
            ],//表头
            dataVal:[],//数据
            pagination:{
                pageNo:1,
                pageSize:10,
                total:0
            }, //分页数据
        };
    },
    methods:{
        closeIDsPopup(){
            this.$emit('closeNotice', false);
        },
        async async_get_list(){
            let obj = {
                status:1,
                pageNo:this.pageNo,
                pageSize:this.pageSize
            }
            let res = await API.DEVICE.newsList(obj)
            if (res.code==200) {
                 
                this.dataVal=res.data.records
                this.pagination.total=res.data.total
            }else{
                this.$message.error(res.message)
            }
        }
    },
    created(){
        this.async_get_list()
    }
}
</script>

<style scoped lang="scss">

</style>