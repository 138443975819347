<template>
  <div ref="move" class="cpt-service-dialog" v-if="visible" :style="containerStyle">
    <div class="dialog-close" v-if="isShowClose">
      <span class="iconfont icon-guanbi" @click="handle_close"></span>
    </div>
    <div
      class="dialog-content"
      :style="`margin-top:${isShowTitle ? '8px' : '0px'};`"
    >
      <div class="dialog-title"  @mouseenter="titleCanMove && mouseDown()" @mouseleave="titleCanMove && dragmove()" v-if="isShowTitle">
        <div class="dialog-inner-title">
          <div v-if="plus" style="marginRight:42px" class="UpAndDown" @click="$emit('redcurr')">
            <img src="~@/assets/images/icons/left-click.png" alt="">
          </div>
          <span :style="plus?'display: inline-block;width:40px':null">{{ this.title }}</span>
          <div v-if="plus" style="marginLeft:42px" class="UpAndDown" @click="$emit('addcurr')">
            <img src="~@/assets/images/icons/right-click.png" alt="">
          </div>
        </div>
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'
export default {
  props: {
    containerStyle: {
      type: Object | String,
      default: () => "",
    },
    title: {
      type: String,
      default: "标题",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    titleCanMove: { // 预警消息传进来这个值，控制只能鼠标移到title才能拖动
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => () => {},
    },
    isShowClose: {
      type: Boolean,
      default: true,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    plus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler(value) {
        
      },
      immediate: true,
    },
  },
  methods: {
    handle_close() {
      this.$emit("update:visible", false);
      this.$emit("onClose");
    },
    moveElement(){  // 元素移动函数
        interact(this.$refs.move)
            .on('dragmove', function (event) {
                x += event.dx
                y += event.dy
                event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
            })
    },
    mouseDown() { //鼠标进入头部开启拖动事件
        interact(this.$refs.move)
            .draggable({
                listeners: {
                    move(event) {
                        const target = event.target
                        const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                        const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

                        target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

                        target.setAttribute('data-x', x)
                        target.setAttribute('data-y', y)
                    },
                },
                modifiers: [
                    interact.modifiers.snap({
                        targets: [
                        interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
                        ],
                        range: Infinity,
                        relativePoints: [ { x: 0, y: 0 } ]
                    }),
                    interact.modifiers.restrict({  // 设置只能在父元素上移动
                        restriction: this.$refs.move.parentNode.parentNode,
                        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                        endOnly: true
                    })
                ],
                inertia: true
            })
            
    },
    dragmove() { //鼠标离开头部元素取消拖动事件
        interact(this.$refs.move).draggable(false)
    },
  }
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
.cpt-service-dialog {
  background: linear-gradient(135deg, rgba(19, 53, 111, 0.8) 0%, rgba(8, 28, 64, 0.8) 100%) !important;
  border: 1px solid #0067ab;
  position: fixed;
  z-index: 111;
  overflow: hidden;
  top: 180px;
  .dialog-close {
    position: absolute;
    top: 12px;
    right: 20px;
    z-index: 1000;
    // height: 16px;
    // box-sizing: border-box;
    // display: flex;
    // align-items: center;
    // justify-content: flex-end;
    // box-sizing: border-box;
    // padding: 0 16px;
    // margin-top: 8px;
    .icon-guanbi {
      z-index: 9;
      margin-top: 60px;
      color: #cce8ff;
      font-size: 20px;
      cursor: pointer;
      font-weight: lighter;
    }
  }
  .dialog-content {
    box-sizing: border-box;
    padding: 0px 12px;
    height: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    .dialog-title {
      flex-shrink: 0;
      background-image: linear-gradient(
        270deg,
        rgba(105, 180, 255, 0) 0%,
        rgba(105, 180, 255, 0.3) 47%,
        rgba(105, 180, 255, 0) 100%
      );
      box-sizing: border-box;
      padding: 4px 0;
      position: relative;
      height: 32px;
      &::before {
        content: " ";
        display: block;
        width: 5px;
        height: 2px;
        background: #4dabff;
        position: absolute;
        left: 0;
        top: 0;
      }
      &::after {
        content: " ";
        display: block;
        width: 5px;
        height: 2px;
        background: #4dabff;
        position: absolute;
        right: 0;
        top: 0;
      }
      .dialog-inner-title {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          270deg,
          rgba(5, 130, 255, 0) 0%,
          rgba(5, 130, 255, 0.5) 47%,
          rgba(5, 130, 255, 0) 100%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        &::before {
          content: " ";
          display: block;
          width: 5px;
          height: 2px;
          background: #4dabff;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        &::after {
          content: " ";
          display: block;
          width: 5px;
          height: 2px;
          background: #4dabff;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .UpAndDown{
          cursor: pointer;
        }
      }
    }
    .dialog-body {
      flex: 1;
      overflow: hidden;
      box-sizing: border-box;
      margin: 15px 0;
    }
  }
}
</style>