<template>
  <div class="wih100 box">
    <div class="wih100 cpt-service-header">
      <div class="basic" @click="onclick_router">
        <img class="logo" src="~@/assets/home/bgtitle.svg" alt="logo" />
      </div>
      <div class="actions">
        <div class="actions-routes" v-if="!type">
          <template v-for="(item, index) in route_list">
            <template v-if="item.children && item.children.length">
              <el-popover
                :key="index"
                placement="bottom"
                trigger="hover"
                popper-class="action-route-popover"
                width="140"
              >
                <div class="action-route-dropdown-list">
                  <div
                    class="action-route-dropdown cp"
                    :class="`${child.path == $route.path ? 'active' : ''}`"
                    v-for="(child, index) in item.children"
                    :key="index"
                    v-view="{ sign: child.sign }"
                    @click="$router.push(child.path)"
                  >
                    {{ child.name }}
                  </div>
                </div>
                <div
                  slot="reference"
                  class="action-route"
                  :class="`${
                    $route.path.indexOf(item.path) !== -1 ? 'active' : ''
                  }`"
                >
                  {{ item.name }}<i class="el-icon-caret-bottom"></i>
                </div>
              </el-popover>
            </template>
            <template v-else>
              <div
                :key="index"
                class="action-route"
                v-view="{ sign: item.sign }"
                :class="`${item.path == $route.path ? 'active' : ''}`"
                @click="routerPush(item)"
              >
                {{ item.name }}
              </div>
            </template>
          </template>
        </div>
        <div class="actions-routes" v-else>
          <template v-for="(item, index) in route_list2">
            <template v-if="item.children && item.children.length">
              <el-popover
                :key="index"
                placement="bottom"
                trigger="hover"
                popper-class="action-route-popover"
                width="140"
              >
                <div class="action-route-dropdown-list">
                  <div
                    class="action-route-dropdown cp"
                    :class="`${child.path == $route.fullPath ? 'active' : ''}`"
                    v-for="(child, index) in item.children"
                    :key="index"
                    v-view="{ sign: child.sign }"
                    @click="$router.push(child.path)"
                  >
                    {{ child.name }}
                  </div>
                </div>
                <div
                  slot="reference"
                  class="action-route"
                  :class="`${
                    $route.path.indexOf(item.path) !== -1 ? 'active' : ''
                  }`"
                >
                  {{ item.name }}<i class="el-icon-caret-bottom"></i>
                </div>
              </el-popover>
            </template>
            <template v-else>
              <div
                :key="index"
                class="action-route"
                v-view="{ sign: item.sign }"
                :class="`${item.path == $route.fullPath ? 'active' : ''}`"
                @click="$router.push(item.path)"
              >
                {{ item.name }}
              </div>
            </template>
          </template>
        </div>
        <div class="actions-info">
          <div class="cf jcsb" v-if="uav && uav.stationType == 1">
            <el-tooltip content="RTK" placement="bottom"
              v-if="uavData.rtk && uavData.rtk.type == 19 && uavData.rtk.isMainSensor">
              <div class="symbol-icon-box ml12">
                <img v-if="uavData && uavData.rtk" :src="signal_rtk[signal_state_rtk]" />
                <img v-else src="./img/RTK-0.svg" />
              </div>
            </el-tooltip>
            <el-tooltip content="GPS" placement="bottom" v-else>
              <div class="symbol-icon-box ml12">
                <img class="dib w30 h30" v-if="uavData && uavData.gps" :src="signal[signal_state]" />
                <img v-else class="dib w30 h30" src="./GPS0.svg" />
              </div>
            </el-tooltip>
            <el-tooltip class="item cfc ml12 mt7" effect="dark" content="搜星数" placement="bottom">
              <div class="item-box">
                <div class="icon-box">
                  <span class="dib w20 h20 souxing">:</span>
                  <span class="f14">{{ satelliteCount }}</span>
                </div>
              </div>
            </el-tooltip>
            <div class="icon-box ml12 cp">
              <el-tooltip :content="`遥控器${{ 0: '未连接', 1: '已连接' }[remote_state]}`" placement="bottom">
                <div class="symbol-icon-box">
                  <div v-if="remote_state == 0" class=" w25 h25 lh20 dklj"></div>
                  <div v-if="remote_state == 1" class=" w25 h25 lh20 ljcg"></div>
                </div>
              </el-tooltip>
            </div>
            <div class="icon-box ml12 mt4">
              <el-tooltip :content="`${{ 0: '外场权限', 1: '内场权限' }[rcState]}`" placement="bottom">
                <div v-if="rcState == 0" class="Outside w25 h20 lh20"></div>
                <div v-if="rcState == 1" class="within w25 h20 lh20"></div>
              </el-tooltip>
            </div>
            <div class="mode_nameBox cp ml12">
              <el-dropdown trigger="click" @command="handle_change_uav_mode">
                <span class="dib">
                  <span class="mode_name">{{ FlightMode }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </span>
                <div class="el-popperBox">
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in modeList" v-show="!item.disabled" :key="item.id"
                      :command="item.data">{{ item.label }}</el-dropdown-item>
                  </el-dropdown-menu>
                </div>
              </el-dropdown>
            </div>
          </div>
          <el-tooltip class="item" effect="dark" content="三维模型申请" placement="bottom" v-if="haves.includes(1)">
            <div class="action-item action-warning" @click="safeflyModel" style="text-align:center">
              <img src="./img/9.png" class=" cp w53 h53" />
            </div>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="安全飞行"
            placement="bottom"
          >
            <div
              class="cp action-item action-warning"
              @click="safefly"
              v-view="{ sign: 'hn:flightCheck' }"
              style="text-align: center"
            >
              <img src="./img/7.png" class="w53 h53" />
            </div>
          </el-tooltip>
          <HeaderMessage
            :message_list="message_list"
            @opennotice="$emit('openNotice', true)"
            :contentState="'layout'"
          />
          <el-tooltip
            class="item"
            effect="dark"
            content="后台管理 "
            placement="bottom"
          >
            <img
              v-show="managementIslogin"
              @click="managementSystem"
              src="./img/1.png"
              class="w53 h53 cp"
              alt=""
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="退出登录"
            placement="bottom"
          >
            <div class="action-item action-user" @click="action">
              <div slot="reference" class="imgIcon">
                <img src="./img/4.png" class="cp w53 h53" />
                <div></div>
                <div></div>
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import img1 from "@/assets/home/headImg1.png";
import HeaderMessage from "@/components/dashboard/header-message";
export default {
  components: { HeaderMessage },
  computed: {
    ...mapGetters(["user_info_hn"]),
    uav() {
      return this.$store.state.mqtt.uav
    },
    satelliteCount() {
      let { uavData } = this;
      if (uavData.rtk && uavData.rtk.type == 19 && uavData.rtk.isMainSensor) {
        return uavData?.rtk?.satelliteCount
      } else {
        return uavData?.gps?.satelliteCount || 0;
      }
    },
    signal_state_rtk() {
      let { uavData } = this;
      if (uavData && uavData.gps.satelliteCount) {
        let count = parseInt(uavData.gps.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.gps.satelliteCount ? count : 1;
      }
      return 1;
    },
    signal_state() {
      let { uavData } = this;
      if (uavData && uavData.gps.satelliteCount) {
        let count = parseInt(uavData.gps.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.gps.satelliteCount ? count : 1;
      }
      return 1;
    },
    remote_state() {
      let { uavData } = this;

      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    uavData() {
      return this.$store.state.mqtt.flyData
    },
    FlightMode() {
      let { modeList, uavData } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      // 用vuex将当前的模式存起来 飞控 无人机列表
      this.$store.commit("mqtt/setState", {
        key: "flightMode",
        value: flightMode?.label
      });
      return flightMode?.label || "离线状态";
    },
    rcState() {
      let { uavData } = this;
      return uavData && uavData.rcState ? uavData.rcState : 0;
    },
    uav() {
      return this.$store.state.mqtt.uav
    }
  },
  data() {
    return {
      departmentName:"",
      signal_rtk: {
        1: require("./img/RTK-1.svg"),
        2: require("./img/RTK-2.svg"),
        3: require("./img/RTK-3.svg"),
        4: require("./img/RTK-4.svg"),
        5: require("./img/RTK-5.svg"),
      },
      signal: {
        1: require("./img/GPS1.svg"),
        2: require("./img/GPS2.svg"),
        3: require("./img/GPS3.svg"),
        4: require("./img/GPS4.svg"),
        5: require("./img/GPS5.svg"),
      },
      remote: {
        0: "断开连接",
        1: "遥控器连接",
      },
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "跟随模式",
          disabled: false,
        },
      ],
      managementIslogin: false,//是否展示 免登录后台管理按钮  权限
      img: img1,
      type: true,
      route_list: [
        {
          name: "飞行管理",
          path: "/new/flightManagement",
          sign: "hn:tasks:manager",
        },
        { name: "飞行指挥", path: "/new/task", sign: "hn:tasks:command" },
        { name: "飞行调度", path: "/new/dispatch", sign: "hn:tasks:schedle" },
        // { name: '飞行控制', path: '/new/control', sign: 'hn:tasks:control' },
        // { name: "返回", path: "/home" },
      ],
      route_list2: [/* { name: "无人机", path: "/new/control" } */ { name: '无人机', path: '/new/control?scene=1', sign: 'hn:tasks:control' },
        { name: '机库', path: '/new/control?scene=2', sign: 'hn:tasks:control' },],
      route_list1: [
        { name: "空域管理", path: "/platform/airspace" },
        { name: "航空航线", path: "/platform/airway" },
        {
          name: "全要素",
          path: "/platform/poi",
          children: [
            {
              name: "信息模式",
              path: "/platform/poi",
              sign: "hn:sky:totalFactor:message",
            },
            {
              name: "可视化模式",
              path: "/platform/visualization",
              sign: "hn:sky:totalFactor:visualization",
            },
          ],
        },
        { name: "三维模型", path: "/platform/model", sign: "hn:sky:model" },
        {
          name: "航空任务",
          path: "/platform/order/task",
          children: [
            // { name: "需求列表", path: "/platform/order/demand" },
            // { name: "任务列表", path: "/platform/order/task" },
          ],
        },
        {
          name: "数据看板",
          children: [],
        },
        // {
        //   name: "人员看板",
        //   path: "/platform/staff",
        //   sign: "hn:sky:personnelBoard",
        // },
        // { name: "设备看板", path: "/platform/device" },
        // { name: "AI视频", path: "/platform/aivideo", sign: "hn:sky:AIvideo" },
        // { name: "反制侦察", path: "/counteract" },
        // { name: "航空设备", path: "/platform/aeronautical" }
        // { name: "飞行作业", path: "/platform/flyWork" },
      ],
      message_list: [],
      message_list1: [
        { name: "预警消息", path: "/platform/message/warning", type: 0.5 },
        { name: "需求消息", path: "/platform/message/order", type: 60 },
        { name: "审批消息", path: "/platform/message/task", type: 70 },
      ],
      routeMessage: false,
      haves: [],
      // user_list: [
      //   {
      //     name: "退出登录",
      //     action: () => {
      //       this.$confirm("确定要退出登录么?", "提示", {
      //         confirmButtonText: "确定",
      //         cancelButtonText: "取消",
      //         type: "warning",
      //       }).then(() => {
      //         this.handle_logout();
      //       });
      //     },
      //   },
      // ],
    };
  },
  created() {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:modelApplication") > -1) {
      this.haves.push(1);
    }
    if (this.$route.path.indexOf("control") > -1) {
      this.type = true;
    } else {
      this.type = false;
    }
    if (permissionList.indexOf("management:islogin") > -1) {
      this.managementIslogin = true;
    }
    /* if (permissionList.indexOf("cockpit:home:hangar") > -1) {
      let jk = { name: "机库", path: "", sign: "hn:tasks:command" };
      this.route_list2.push(jk);
    } */

    // control
    // if (permissionList.indexOf("hn:sky:basicResources:airspace") > -1) {
    //   this.route_list.push(this.route_list1[0]);
    // }

    // if (permissionList.indexOf("hawk:control:flightLine:flightLineList") > -1) {
    //   this.route_list.push(this.route_list1[1]);
    // }
    // if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:message") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:visualization") > -1
    // ) {
    //   this.route_list.push(this.route_list1[2]);
    // }
    // else if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:message")
    // ) {
    //   this.route_list.push({
    //     name: "全要素",
    //     path: "/platform/poi",
    //     children: [{ name: "信息模式", path: "/platform/poi" }],
    //   });
    // } else if (
    //   permissionList.indexOf("hn:sky:totalFactor") > -1 &&
    //   permissionList.indexOf("hn:sky:totalFactor:visualization") > -1
    // ) {
    //   this.route_list.push({
    //     name: "全要素",
    //     path: "/platform/poi",
    //     children: [{ name: "可视化模式", path: "/platform/visualization" }],
    //   });
    // }

    // if (permissionList.indexOf("hn:sky:model") > -1) {
    //   this.route_list.push(this.route_list1[3]);
    // }
    // if (permissionList.indexOf("hn:task-web") > -1) {
    //   this.route_list.push(this.route_list1[4]);
    // }
    // if (permissionList.indexOf("hn:sky:personnelBoard") > -1 || permissionList.indexOf("hn:sky:deviceBoard") > -1) {
    //   if(permissionList.indexOf("hn:sky:personnelBoard") > -1){
    //     this.route_list1[5].children.push(
    //       {
    //         name: "人员看板",
    //         path: "/platform/staff",
    //         sign: "hn:sky:personnelBoard",
    //       }
    //     )
    //   }
    //   if(permissionList.indexOf("hn:sky:deviceBoard") > -1){
    //     this.route_list1[5].children.push(
    //       {
    //           name: "设备看板",
    //           path: "/platform/device",
    //           sign: "hn:sky:deviceBoard",
    //         }
    //     )
    //   }
    //   this.route_list.push(this.route_list1[5]);
    // }

    if (permissionList.indexOf("hn:sky:AIvideo") > -1) {
      // this.route_list.push(this.route_list1[6]);
    }
    if (permissionList.indexOf("hn:message") > -1) {
      this.routeMessage = true;
    }
    this.message_list.push({ name: "公告消息", type: 0.5 });
    this.hidden();
    // if(permissionList.indexOf("hn:sky:historyWaringMessage") > -1) {
    //   this.message_list.push({ name: "历史预警", path: "/platform/message/warning", type: 0.5 })
    // }
    // if(permissionList.indexOf("hn:sky:needMessage") > -1) {
    //   this.message_list.push({ name: "需求消息", path: "/platform/message/order", type: 60 })
    // }
    // if(permissionList.indexOf("hn:sky:approvalMessage") > -1) {
    //   this.message_list.push({ name: "审批消息", path: "/platform/message/task", type: 70 })
    // }
  },
  methods: {
    async handle_change_uav_mode(data) {
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$store.dispatch("mqtt/publishMessage", {
          topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
          data: { type: 513, data: mode.data }
        });
      }
    },
    managementSystem() {
      const { VUE_APP_WS_IP: WS_IP } = process.env;
      let code = localStorage.getItem("user_key");
      window.open(`${WS_IP}/hawk_back/#/?code=${code}`, "_blank");
    },
    safeflyModel() {
      this.$emit("safeflyModel", true);
    },
    onclick_router() {
      // if (this.$route.fullPath == '/home?id=9') {
      //   this.$router.go(-1)
      // } else {
      //   this.$router.push({ name: 'home', params: { id: 2 } })
      // }
      if (this.$route.fullPath == "/home?id=9") {
        this.$router.push({ name: "home", params: { id: 5 } });
      } else if (this.$route.fullPath.indexOf("civilAircraft") > -1) {
        this.$router.push({ name: "home", params: { id: 3 } });
      } else {
        this.$router.push({ name: "home", params: { id: 1 } });
      }
    },
    routerPush(item) {
      if (item.name == "机库") {
        let code = null;
        // 是否是局办
        if (this.isJB &&this.departmentName!='公安局') {
          code = "1";
        } else {
          code = "2";
        }

        // let firstWindow = window.open(
        //   `http://localhost:8081/newyingchao/#/?code=${code}`,
        //   "_blank"
        // );
        // firstWindow.onload = function (params) {
        //   // 关闭第一个窗口
        //   firstWindow.close();
        //   window.open(
        //     `http://localhost:8081/newyingchao/#/?code=${code}`,
        //     "_blank"
        //   );
        // };
        window.open(
          `https://fkzx.hnjcloud.cn/newyingchao/#/?code=${code}`,
          "_blank"
        );
        // firstWindow.onload = function (params) {
        //   // 关闭第一个窗口
        //   firstWindow.close();
        //   window.open(
        //     `http://10.230.22.32/newyingchao/#/?code=${code}`,
        //     "_blank"
        //   );
        // };
      } else if (item.path == "/home") {
        this.$router.push({ name: "home", params: { id: 1 } });
      } else {
        this.$router.push(item.path);
      }
    },
    ...mapActions("user", ["Logout"]),
    handle_logout() {
      this.Logout();
    },
    hidden() {
      const path = this.$route.fullPath;
      if (path === "/platform/order/visual?mode=1") {
        this.route_list = [
          { name: "首页", path: "/home" },
          { name: "航空设备", path: "/platform/airspace" },
        ];
      } else if (path === "/home?id=9") {
        this.route_list = [
          // { name: "三维标注", path: "/home?id=9" },
        ];
      } else if (path.indexOf("civilAircraft") > -1) {
        this.route_list = [
          // { name: "飞行服务", path: "/civilAircraft/flightService" },
          { name: "设备管理", path: "/civilAircraft/device" },
          { name: "证照管理", path: "/civilAircraft/certificate" },
          { name: "报飞备案", path: "/civilAircraft/record" },
          { name: "信息管理", path: "/civilAircraft/information" },
        ];
      }
    },
    safefly() {
      this.$emit("sadeflyshow", true);
    },
    safefly1() {
      this.$emit("sadeflyshow2", true);
    },
    action() {
      this.$confirm("确定要退出登录么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handle_logout();
      });
    },
  },

  mounted() {
    if (this.user_info_hn && this.user_info_hn.departmentId) {
      let departmentId = this.user_info_hn.departmentId;
      if (departmentId == 60) {
        // 局办
        this.isJB = true;
      } else {
        this.isJB = false;
      }
      this.departmentName= this.user_info_hn.departmentName
    }
    // this.hidden();
  },
};
</script>

<style lang="scss">
.action-route-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;

  .popper__arrow {
    display: none;
  }

  .action-route-dropdown-list {
    .action-route-dropdown {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;

      &:hover,
      &.active {
        color: #2cacff;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.Outside {
  background: url("~@/assets/images/Outside.svg");
  background-repeat: no-repeat;
}

.within {
  background: url("~@/assets/images/within.svg");
  background-repeat: no-repeat;
}

.ljcg {
  background: url("~@/assets/images/ljcg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.souxing {
  background: url("~@/assets/images/souxin.svg");
  background-repeat: no-repeat;
}

.dklj {
  background: url("~@/assets/images/dklj.svg");
  background-repeat: no-repeat;
  background-size: 100%;

}

.box {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  background: rgba(12, 34, 67, 0.7);
  height: 80px;
}

.cpt-service-header {
  height: 80px;

  background: left center url(~@/assets/platform/header-bg.png) no-repeat;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 0;
  justify-content: space-between;
  flex-shrink: 0;

  .mode_nameBox {
    box-sizing: border-box;
    // width: 116px;
    height: 28px;
    background-size: contain;
    margin-right: 5px;
    display: flex;
    align-items: center;

    .mode_name {
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      color: #3df1ff;
      margin: 0 6px 0 0;
    }
  }



  .basic {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .logo {
      width: 372px;
      height: 100%;
      height: 30px;
      margin-left: 6px;
    }

    .title {
      font-family: PangMenZhengDao;
      font-size: 36px;
      color: #e0e6f2;
      letter-spacing: 4px;
      font-weight: 400;
      background-image: linear-gradient(180deg, #ffffff 0%, #428fde 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 64%;

    .actions-routes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 67px;
      /* width: 1140px; */
      box-sizing: border-box;

      .action-route-popover {
        margin-right: 16px;

        &:last-child {
          margin-right: 0px;
        }

        .action-route-dropdown {
          border: 1px solid #488cff;
        }
      }

      .action-route {
        // width: 124px;
        // height: 44px;
        // background: center center url(~@/assets/platform/route-btn.png)
        //   no-repeat;
        // background-size: 100% 100%;
        // color: #9fc4ff;
        // font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        // transition: 0.3s;
        // cursor: pointer;
        // box-sizing: border-box;
        // letter-spacing: 1px;
        // margin-left: 5px;

        width: 150px;
        height: 32px;
        margin-left: 28px;
        cursor: pointer;
        background: linear-gradient(
          180deg,
          #5298df 0%,
          #25518f 60%,
          #0f3275 100%
        );
        box-shadow: 0px 2px 4px 0px rgba(3, 5, 49, 0.5),
          inset 0px 0px 23px 0px #69e6ff, inset 0px -2px 2px 0px #120d59,
          inset 0px 3px 4px 0px #a7d8ff;
        border-radius: 6px;
        border: 2px solid;
        border-image: linear-gradient(
            180deg,
            rgba(219, 242, 255, 1),
            rgba(132, 210, 255, 0)
          )
          2 2;
        transform: skew(-20deg, 0);

        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 31px;
        // background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 36%, #7897DC 100%);

        .el-icon-caret-bottom {
          margin-top: -3px;
          transition: 0.3s;
        }

        &:hover {
          .el-icon-caret-bottom {
            transform: rotate(180deg);
          }
        }

        &:hover,
        &.active {
          background: linear-gradient(
            180deg,
            #ffca00 0%,
            rgba(255, 175, 0, 0.2) 51%,
            rgba(255, 226, 0, 0.5) 100%
          );
          box-shadow: 0px 2px 4px 0px rgba(49, 20, 3, 0.5),
            inset 0px 0px 23px 0px #ffe400, inset 0px -2px 2px 0px #59330d,
            inset 0px 3px 4px 0px #ffd8a7;
          border-image: linear-gradient(
              180deg,
              rgba(255, 239, 188, 1),
              rgba(255, 229, 132, 0)
            )
            2 2;
        }
      }
    }

    .actions-info {
      // margin-left: 100px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-left: 24px;

      .action-item {
        // width: 50px;
        height: 52px;
        /* border: 1px solid red; */
        // margin-right: 6px;
        box-sizing: border-box;
        // display: flex;
        // align-items: center;
        justify-content: center;
        // background: center center #103a78;
        // url(~@/assets/platform/header-icon-bg.png) no-repeat;
        // background-size: 100% 100%;
        transition: 0.3s;
        cursor: pointer;

        &:last-child {
          margin-right: 0px;
        }

        // &:hover {
        //   background: center center #06214b
        //   url(~@/assets/platform/header-icon-bg.png) no-repeat;
        // }
        .iconfont {
          color: #fff;
          font-size: 25px;
        }

        .text {
          font-size: 12px;
          color: #9fc4ff;
          width: 76px;
          text-align: left;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // margin-top: 4px;
        }
      }

      .action-user {
        display: block;
        text-align: center;
        // width: 100%;
      }
    }

    .safefly-image {
      width: 50px;
      height: 24px;
      // background-image: url(~@/assets/platform/security.png);
      // background-repeat: no-repeat;
      // background-position: center;
    }

    .check-image {
      width: 50px;
      height: 24px;
      // background-image: url(~@/assets/platform/inspect.png);
      // background-repeat: no-repeat;
      // background-position: center;
    }

    .messageIcon {
      width: 50px;
      height: 24px;
      // background-image: url(~@/assets/platform/message.png);
      // background-repeat: no-repeat;
      // background-position: center;
    }

    .imgIcon {
      // width: 121px;
      height: 52px;
      // padding-top: 3px;
      // padding-left: 10px;
      display: flex;
      // justify-content: flex-end;
      align-items: center;
      // background-image: url("~@/assets/home/avatarbg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .text {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #daf4ff;
        text-align: right !important;
        margin-top: 0px;
        // transform: scale(0.833);
        // -webkit-transform: scale(0.833);
      }
    }
  }
}

.iconfont {
  cursor: pointer;
  font-size: 18px;
  color: #9fc4ff;
  margin-right: 4px;

  &:last-child {
    margin-right: 0px;
  }
}
</style>
<style>
.el-dropdown-menu {
  background: rgba(9, 32, 87, 0.5) !important;

  .el-dropdown-menu__item {
    background: rgba(9, 32, 87, 0.5);
    color: #fff !important;
  }

  .el-dropdown-menu__item:hover {
    /* color: #43deff !important; */
    background-color: #043974d4;
  }
}
</style>