<template>
  <div class="cpt-player-webrtc">
    <video ref="srs-player" controls autoplay></video>
  </div>
</template>

<script>
let sdk = null;

export default {
  props: {
    id: {
      type: String,
      default: () => "",
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      video: {
        vUrl: "",
      },
    };
  },
  methods: {
    init() {
      // if (this.$refs["srs-player"]) {
      //   let { video } = this;
      //   if (sdk) {
      //     sdk.close();
      //   }
      //   sdk = new SrsRtcPlayerAsync();
      //   this.$refs["srs-player"].srcObject = sdk.stream;
      //   sdk
      //     .play(video.vUrl)
      //     .then(function (session) {
      //       console.log(session);
      //     })
      //     .catch(function (reason) {
      //       sdk.close();
      //       console.error(reason);
      //     });
      // }
      let { video } = this;

      this.player = new JSWebrtc.Player(video.vUrl, {
        video: this.$refs["srs-player"],
        autoplay: true,
      });
    },
  },
  watch: {
    dataSource: {
      handler(value) {
        if (value && value.length) {
          console.log(value,'video');
          this.video = value.find((item) => item.videoType.indexOf("flv") !== -1);
          this.$nextTick(() => {
            // this.$refs["liveqing-player"].play();
            this.init();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    
  }
};
</script>

<style lang="scss" scoped>
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>