<template>
  <div class="page-task-create">
    <!-- 发起需求弹框 -->
    <el-dialog
      :title=" '需求单制定'"
      :visible.sync="createShow"
      v-if="createShow"
      width="720px"
      top="150px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="launchTask">
        <el-form
          ref="edit_form"
          :rules="rules.edit_form"
          :model="edit_form"
          style="padding-right: 60px"
          class="linear-form vertical edit-form"
          label-width="140px"
        >
          <el-form-item label="需求单名称:" prop="taskTitle">
            <el-input
              v-model="edit_form.taskTitle"
              placeholder="请输入需求单名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="任务类型:" prop="taskCateId">
            <el-select
              v-model="edit_form.taskCateId"
              placeholder="请选择任务类型"
              clearable
              :disabled="editList != null"
            >
              <el-option
                v-for="item in drd.task_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="需求单位:" class="aaxx" prop="dutyOrganId">
            <el-cascader
              class="input-orgname"
              placeholder="请选择需求单位"
              v-model="edit_form.dutyOrganId"
              :options="selectList"
              :props="{
                children: 'child',
                label: 'org_NAME',
                value: 'id',
                checkStrictly: true,
                emitPath: true,
                empty: false,
              }"
              clearable
              @change="selectChange(edit_form.dutyOrganId, selectList)"
            >
            </el-cascader>
          </el-form-item> -->
          <el-form-item label="场景类型:" prop="taskTypeId">
            <el-select
              v-model="edit_form.taskTypeId"
              placeholder="请选择场景类型"
              clearable
              :disabled="editList && editList.applyStatusName != '待审批'"
              @change="ChangeTaskType(edit_form.taskTypeId, drd.task_cate)"
            >
              <el-option
                v-for="item in drd.task_cate"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="飞行时间:" required>
            <div class="timecss">
              <el-form-item prop="date1" style="margin-bottom: 0px; flex: 1">
                <el-date-picker
                  v-model="edit_form.date1"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptionsStart"
                  type="datetime"
                  placeholder="开始日期"
                  @focus="dateStartPickerFocus"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
              <span style="width: 50px; color: #009aff; text-align: center"
                >—</span
              >
              <el-form-item prop="date2" style="margin-bottom: 0px; flex: 1">
                <el-date-picker
                  v-model="edit_form.date2"
                  :picker-options="pickerOptionsEnd"
                  @focus="dateEndPickerFocus"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="结束日期"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="任务目标位置:" prop="destination">
            <el-cascader
              class="input-orgname"
              placeholder="请选择街道"
              v-model="edit_form.destination"
              :options="drd.cityType"
              :props="{
                children: 'children',
                label: 'title',
                value: 'id',
                emitPath: false,
                empty: false,
              }"
              clearable
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.title }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>

          <el-form-item label="详细位置说明:" required>
            <div style="display: flex; align-items: center">
              <el-form-item style="margin-bottom: 0px; flex: 1" prop="origin">
                <el-input
                  v-model="edit_form.origin"
                  placeholder="请输入详细位置"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item
            label="处理状态"
            prop="processingStatus"
            v-if="editList"
          >
            <el-select
              v-model="processingStatus"
              placeholder="请选择处理状态"
              clearable
              disabled
            >
              <el-option
                v-for="item in drd.proStatus"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
         

          <el-form-item label="区域选择:">
            <span
              @click="mapSelectionArea = true"
              :class="[areaPointList ? 'selected-area ' : 'noselected-area']"
            >
              {{ areaPointList ? "(点击修改区域)" : "(点击选择区域)" }}
            </span>
          </el-form-item>
          <el-form-item label="需求描述:" style="width: 100%" prop="remark">
            <el-input
              v-model="edit_form.remark"
              placeholder="请输入需求描述"
              type="textarea"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 自主巡检开关 -->
          <el-form-item
            label="自主巡检:"
            prop="Inspection"
            v-view="{ sign: 'hn:task-web:create:flight' }"
          >
            <el-switch v-model="Inspection" class="Inspection"></el-switch>
          </el-form-item>

          <el-form-item
            label="分配航线:"
            prop="flightLineId"
            v-view="{ sign: 'hn:task-web:create:flight' }"
            v-if="Inspection"
          >
            <el-select
              ref="selectChange4"
              v-model="edit_form.flightLineId"
              placeholder="请选择航线"
              clearable
            >
              <el-option
                v-for="item in drd.flightLine"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
          <div class="foot-submit-item">
            <div
              @click="handle_save"
              style="margin-left: 70px;"
              class="linear-btn"
            >
              确认
            </div>
          </div>
    </el-dialog>
    <!-- 区域选择弹框 -->
    <div v-if="mapSelectionArea" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="cancelSaveArea" type="primary">取消</el-button>
          <el-button @click="saveArea" type="primary">保存</el-button>
        </div>
        <Drawky
          :leftOnclick="(value) => leftOnclick(value)"
          :rigthOnclick="(value) => rigthOnclick(value)"
          :move="(value) => move(value)"
          :airspcelist="areaPointList"
          :drakyStyle="true"
          :addallairArray="(value) => addallairArray(value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import * as turf from '@turf/turf'
import dayjs from "dayjs";
import Drawky from "@/components/drawky"; // 绘制区域的
import { transformCartesian2WGS84 } from "@/utils/cesium";

export default {
  props: {
    createShow: {
        type: Boolean,
        default: false
    },
    clear: {
        type: Function,
        default: () => () => {}
    }
  },
  components: {
    Drawky,
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    let start = (rule, value, callback) => {
      this.workDataRules();
      callback();
    };
    let end = (rule, value, callback) => {
      this.workDataRules();
      callback();
    };
    let editTime = (rule, value, callback) => {
      value.forEach((v) => {
        if (v !== null) {
          if (!v.start || !v.end) {
            callback(new Error("请选择详细作业时间"));
          }
        }
      });
      callback();
    };
    return {
      areaPointList: null, // 发起需求区域绘制点对象
      regionList: null, // 存储选择区域点的原始x y z数据
      mapSelectionArea: false, // 地图选择区域控制弹框显示隐藏
      pickerOptionsStart: {}, //预计飞行开始时间限制
      pickerOptionsEnd: {}, //预计飞行结束时间限制
      detailsPickerOptionsStart: {}, //详细作业开始时间限制
      detailsPickerOptionsEnd: {}, //详细作业结束时间限制
      timer: null,
      nameSearch: [],
      visible: {
        edit: true,
        notice: true,
        again: true,
      },
      processingStatus: null,
      editList: null,
      edit_form: {
        taskTitle: null,
        taskCateId: 2,
        taskTypeId: null,
        date1: null,
        date2: null,
        origin: null,
        destination: null,
        remark: null,
        dutyOrganId: null,
        // deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        // mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        // providePersonCount: null,
        // provideCarCount: null,
        workDate: [{ start: null, end: null }],
        locationArea: null, // 存储区域字符串
        flightLineId: null, // 存储航线
      },
      cahgeDay1: 0, // 看预计开始时间是不是第一次变化
      cahgeDay2: 0, // 看预计结束时间是不是第一次变化
      rules: {
        edit_form: {
          taskTitle: [
            { required: true, message: "请输入任务名称", trigger: "blur" },
          ],
          taskCateId: [
            { required: true, message: "请选择任务类型", trigger: "change" },
          ],
          date1: [
            {
              required: true,
              message: "请选择飞行开始时间",
              trigger: "blur",
            },
          ],
          date2: [
            {
              required: true,
              message: "请选择飞行结束时间",
              trigger: "blur",
            },
          ],
          start: [{ required: true, validator: start, trigger: "blur" }],
          end: [{ required: true, validator: end, trigger: "blur" }],
          origin: [
            { required: true, message: "请输入详细位置", trigger: "blur" },
          ],
          // destination: [
          //   { required: true, message: "请输入结束位置", trigger: "blur" },
          // ],
          // workDate: [
          //   // { required: true, message: "请选择详细作业时间", trigger: "blur" },
          //   // { required: true, validator: editTime, trigger: "change" },
          //   { required: true, validator: editTime, trigger: "blur" },
          // ],
          remark: [
            { required: true, message: "请输入需求描述", trigger: "blur" },
          ],
          taskTypeId: [
            { required: true, message: "请选择场景类型", trigger: "change" },
          ],
          destination: [
            { required: true, message: "请选择街道", trigger: "change" },
          ],
          destination: [
            { required: true, message: "请选择街道", trigger: "blur" },
          ],
          // dutyOrganId: [
          //   { required: true, message: "请选择需求单位", trigger: "change" },
          // ],
        },
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        proStatus: [
          { id: 1, label: "待处理", value: 0 },
          { id: 2, label: "处理完成", value: 1 },
          { id: 3, label: "无需处理", value: 2 },
        ],
        cityType: [
          {
            id: 13,
            title: "济源市",
            children: [
              { id: 1, title: "沁园街道" },
              { id: 2, title: "济水街道" },
              { id: 3, title: "北海街道" },
              { id: 4, title: "天坛街道" },
              { id: 5, title: "玉泉街道" },
              { id: 6, title: "克井镇" },
              { id: 7, title: "五龙口镇" },
              { id: 8, title: "轵城镇" },
              { id: 9, title: "承留镇" },
              { id: 10, title: "邵原镇" },
              { id: 11, title: "坡头镇" },
              { id: 12, title: "梨林镇" },
              { id: 17, title: "大峪镇" },
              { id: 14, title: "思礼镇" },
              { id: 15, title: "王屋镇" },
              { id: 16, title: "下冶镇" },


            ],
          },
        ],
      },
      selectList: null,
      Inspection: false,
      dutyOrganName: null,
      taskTypeName: null,
      allairArray: [], //存储所有空域信息
      dataval:[] //将后台返回的机构处理后只存入当前登录帐号机构以及子机构
    };
  },
  methods: {
    async FlightLine() {
      // 获取航线下拉
      const { data } = await API.TASK.Drd();
      this.drd.flightLine = data;
    },
    workDataRules() {
      //判断详细时间数据内的开始时间与结束时间是否存在
      //存在的话就清除表单验证
      let workDateBoolean = this.edit_form.workDate.every(
        (v) => v.start && v.end
      );
      if (workDateBoolean) this.$refs.dateWork.clearValidate();
    },
    //-------------------------------------------------------------------------------绘制区域函数
    leftOnclick(data) {
      // if (data.index === -1) {
        this.regionList = data.positions;
      // }
    },
    rigthOnclick(data) {
      if (data.positions.type === 1) {
      } else {
        this.regionList = data.positions;
      }
    },
    move(data) {
      this.regionList = data.positions;
    },
    saveArea() {
      // 绘制区域弹框点击保存
      if (this.regionList && this.regionList.length > 2) {
        this.areaPointList = { regionList: [] };
        let wsg84 = [];
        this.regionList.map((item) =>
          wsg84.push(transformCartesian2WGS84(item))
        );
        wsg84.map((item) =>
          this.areaPointList.regionList.push(item.lng, item.lat)
        );

        // 将绘制区域经纬度转成符合比较的多维数组
        let newData = [];
        for (let i = 0; i < Math.ceil(this.areaPointList.regionList.length / 2); i++) {
            newData[i] = [];
            newData[i].push(this.areaPointList.regionList[i * 2]);
            newData[i].push(this.areaPointList.regionList[i * 2 + 1]);
        }
        newData.push(newData[0])

        // 将所有禁飞区转成符合比较的多维数组
        let allDate = [];
        this.allairArray.map(item => {
          let mapList = [];
          for (let i = 0; i < Math.ceil(item.length / 2); i++) {
            mapList[i] = [];
            mapList[i].push(item[i * 2]);
            mapList[i].push(item[i * 2 + 1]);
            mapList.push(mapList[0])
        }
        allDate.push(mapList)
        })

         let box = []
        allDate.forEach(v=>{ //验证绘画的点是否在多边形内
          newData.forEach(value=>{
            let pt = turf.point(value);
            let poly = turf.polygon([v])
            box.push(turf.booleanPointInPolygon(pt, poly))
          })
        }) 
            if (box.some(v=>v==true)) {
              this.areaPointList=null
              this.$message.warning('禁飞区内禁止绘制任务区域')
            }else {
              this.mapSelectionArea = false;
            }

        // 比较绘制区域是否与所有禁飞区域重叠
        // let poly1 = turf.polygon([newData])
        // let poly2 = turf.polygon(allDate)
        // if(turf.booleanOverlap(poly1, poly2)) {
        //   this.$message.warning("禁飞区内禁止绘制任务区域");
        // } else {
        //   this.mapSelectionArea = false;
        // }

      } else {
        this.$message.warning("请先绘制完区域");
      }
    },
    oneArrToTwoArr(data) {
      let newData = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        newData[i] = [];
        newData[i].push(data[i * 2]);
        newData[i].push(data[i * 2 + 1]);
      }
      return newData;
    },
    cancelSaveArea() {
      // 取消保存
      this.mapSelectionArea = false;
    },
    // -------------------------------------------------------------------------------时间选择限制开始
    dateStartPickerFocus() {
      // 预计飞行开始时间获取焦点做选择限制
      this.pickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    dateEndPickerFocus() {
      // 预计飞行结束时间获取焦点做选择限制
      this.pickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          if (
            this.edit_form.date1 &&
            new Date(this.edit_form.date1).getTime() > Date.now()
          ) {
            return (
              time.getTime() < new Date(this.edit_form.date1).getTime() - 8.64e7
            );
          } else {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    detailsDateStartPickerFocus() {
      // 详细作业开始时间获取焦点做选择限制
      this.detailsPickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return (
            time.getTime() <
              new Date(this.edit_form.date1).getTime() - 8.64e7 ||
            time.getTime() > new Date(this.edit_form.date2).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    detailsDateEndPickerFocus(index) {
      // 详细作业结束时间获取焦点做选择限制
      this.detailsPickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          let day1 = this.edit_form.date1;
          if (this.edit_form.workDate[index].start) {
            day1 = this.edit_form.workDate[index].start;
          } // -8.64e7是为了能选同一天， +1 是为了他选了那天的时间00:00:00不能选前一天的
          return (
            time.getTime() < new Date(day1).getTime() - 8.64e7 + 1 ||
            time.getTime() > new Date(this.edit_form.date2).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    getNowTime(val) {
      // 时间时分秒限制
      let date;
      if (val && new Date(val).getTime() > Date.now()) {
        date = new Date(val);
      } else {
        date = new Date();
      }
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    //--------------------------------------------------------------------------------时间选择限制结束
    nanoid() {
      return nanoid();
    },
            dataFilter(){
      if (this.edit_form.startedAt || this.edit_form.endAt) {
        if (this.edit_form.startedAt == this.edit_form.endAt) {
        console.log(20221113);
        return true
      }
      }
      
    },
    async handle_save() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let dataBoolean =  this.dataFilter()
          if(dataBoolean) return this.$message.warning('时间不能相同')
          if (this.areaPointList && this.areaPointList.regionList.length > 2) {
            // 画了区域的话转一下格式
            let regionListCopy = this.areaPointList.regionList.concat();
            let first = regionListCopy[0],
              last = regionListCopy[regionListCopy.length - 1];
            regionListCopy.shift();
            regionListCopy.pop();
            let fv = [];
            let asx = this.oneArrToTwoArr(regionListCopy);
            asx.map((item) => fv.push([item[0], item[1]].join(" ")));
            let gg = fv.toString();
            let yg = first + "," + gg + "," + last;
            this.edit_form.locationArea = yg;
          }

          let { destination, date1, date2, workDate, ...rest } = this.edit_form;

          // workDate.forEach((item) => {
          //   (item.start = dayjs(item.start).format("YYYY-MM-DD HH:mm:ss")),
          //     (item.end = dayjs(item.end).format("YYYY-MM-DD HH:mm:ss"));
          // });
          const params = {
            ...rest,
            startedAt: dayjs(date1).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(date2).format("YYYY-MM-DD HH:mm:ss"),
            type: this.Inspection ? 1 : null,
          };
          //下拉框id转换街道
          destination = this.cityTypeIdFile(destination);
          params.destination = destination;
          //将选择的单位（数组）改为最后选择的单位
          // params.dutyOrganId=params.dutyOrganId[params.dutyOrganId.length - 1]
          if (!this.editList) {
            const res = await API.TASK.Add(params);
            if (res.status === 1) {
              this.$message.success("创建成功");
              this.$refs["edit_form"].resetFields();
              this.handleClose();
              this.visible.edit = false;
              // this.$router.push(`platform/order/task`)
            } else {
              this.$message.error("创建失败，" + res.message);
            }
          }
        }
      });
    },
    cityTypeIdFile(val) {
      if (val == 13) {
        return "济源市";
      } else {
        //筛选出选择的街道
        return this.drd.cityType[0].children.filter(
          (v) => this.edit_form.destination == v.id
        )[0].title;
      }
    },
    cityTypeIdFiler(val) {
      //筛选出选择的街道
      if (val.destination == "济源市") {
        return 13;
      } else {
        //筛选出选择的街道
        let data = this.drd.cityType[0].children.filter(
          (v) => val.destination == v.title
        )[0];
        if (data) {
          return data.id;
        } else {
          return null;
        }
      }
    },
    async drd_task_type() {
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data;
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_cate() {
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data;
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_uav_type() {
      try {
        let res = await API.UAV.GetDrdType();
        res = res.data;
        this.drd.uav_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_mount_type() {
      try {
        let res = await API.MOUNT.GetDrdType();
        res = res.data;
        this.drd.mount_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    editshow(value, type) {
      if (type) {
        this.visible.again = true;
      } else {
        this.visible.again = false;
      }

      let date002 = [];
      if (value.gzTime && value.gzTime.length > 0) {
        value.gzTime.map((item) =>
          date002.push({ start: item.start, end: item.end })
        );
      } else {
        date002 = [{ start: null, end: null }];
      }
      let newval = JSON.parse(JSON.stringify(value));
      newval.destination = this.cityTypeIdFiler(value);
      this.editList = newval;
      this.init_form(newval);
      this.processingStatus = value.processingStatus;
      this.edit_form.date1 = value.startedAt;
      this.edit_form.date2 = value.endAt;
      this.cahgeDay1 = 0;
      this.cahgeDay2 = 0;
      this.edit_form.workDate = date002;
      if (value.locationArea) {
        this.areaPointList = { regionList: null };
        let araeArr = [];
        araeArr = value.locationArea.split(" ");
        let lastAraeArr = [];
        araeArr.forEach((item) => {
          let areaPoint = item.split(",");
          lastAraeArr.push(areaPoint[0] * 1);
          lastAraeArr.push(areaPoint[1] * 1);
        });
        this.areaPointList.regionList = lastAraeArr;
      }

      // if(this.edit_form.deviceType.length > 0) {
      //   this.edit_form.deviceType = value.deviceType
      // } else {
      //   this.edit_form.deviceType = [{ deviceTypeId: null, count: null, key: nanoid() }]
      // }
      // if (this.edit_form.mountType.length > 0) {
      //   this.edit_form.mountType = value.mountType
      // } else {
      //   this.edit_form.mountType = [{ mountTypeId: null, count: null, key: nanoid() }]
      // }
      this.visible.edit = true;
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key] || null;
      });
    },
    handleClose() {
      this.editList = null;
      this.$refs["edit_form"].resetFields();
      // 清除表单验证提示信息
      this.$refs["edit_form"].clearValidate();
      // this.edit_form.deviceType = [{ deviceTypeId: null, count: null, key: nanoid() }],
      // this.edit_form.mountType = [{ mountTypeId: null, count: null, key: nanoid() }],
      this.edit_form.taskTitle = null;
      this.edit_form.taskCateId = 2;
      this.edit_form.taskTypeId = null;
      this.edit_form.date = null;
      this.edit_form.origin = null;
      this.edit_form.destination = 13;
      this.edit_form.remark = null;
      this.edit_form.dutyOrganId = null;
      // this.edit_form.providePersonCount = null;
      // this.edit_form.provideCarCount = null
      this.edit_form.workDate = [{ start: null, end: null }];
      this.edit_form.date1 = null;
      this.edit_form.date2 = null;
      this.cahgeDay1 = 0;
      this.cahgeDay2 = 0;
      this.areaPointList = null;
      this.dutyOrganName = null;
      this.taskTypeName = null;
      this.clear()
    },
    async getAllOrganization() {
      let { data } = await API.AIRWAY.getAllOrganizationBack();
      this.getTreeData(data,this.$store.getters.user_info_hn.departmentId)
      // this.selectList = this.getTreeData(data);
      this.selectList = this.dataval
    },
    getTreeData(data,id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id==id) {
             this.dataval.push(data[i])
            }
        if (data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].child,id);
        }
      }
      return data;
    },
    //同时选择需求单位和场景类型自动生成任务名称
    selectChange(dutyOrganId, selectList) {
      let dutyOrganList=[]
      this.selectOrg(dutyOrganId, selectList,dutyOrganList);
      if (this.edit_form.dutyOrganId && this.edit_form.taskTypeId) {
        this.edit_form.taskTitle = `${this.dutyOrganName
        }-${this.taskTypeName}-${dayjs().format("YYYYMMDD-HH:mm:ss")}`;
        this.$refs["edit_form"].clearValidate(['taskTitle']);
      }
      this.$refs["edit_form"].clearValidate(["taskTitle"]);
    },
    selectOrg(dutyOrganId, selectList) {
      selectList.map((item) => {
        if (item.id == dutyOrganId) {
          this.dutyOrganName = item.name;
          return;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (item.child && item.child.length > 0) {
            this.selectOrg(dutyOrganId, item.child);
          }
        }
      });
    },
    // 通过机构编码遍历得出机构名称
    selectOrg(dutyOrganId, selectList,dutyOrganList) {
      selectList.map(item => {
        //将选择到的单位(数组)遍历寻找相对单位的id
        //如果符合就加入dutyOrganList数组中去，然后使用join拼接成字符串渲染到页面上
        this.edit_form.dutyOrganId.forEach(v=>{
          if (item.id==v) {
            dutyOrganList.push(item.org_NAME)
          this.dutyOrganName = dutyOrganList.join('')
          }
        })
        // if(item.id == dutyOrganId) {
        //   //将获取到的单位的海宁市去掉
        //   this.dutyOrganName = item.name.replace('海宁市','')
        //   return ;
        // } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (item.child && item.child.length > 0) {
            this.selectOrg(dutyOrganId, item.child,dutyOrganList)
          }
        // }
      })
    },
    ChangeTaskType(taskTypeId, task_cate) {
      let taskType = "";
      for (const item of task_cate) {
        if (item.id === taskTypeId) {
          taskType = item.name;
        }
      }
      this.taskTypeName = taskType;
      if (this.edit_form.dutyOrganId && this.edit_form.taskTypeId) {
        this.edit_form.taskTitle = `${
          this.dutyOrganName
        }-${this.taskTypeName}-${dayjs().format("YYYYMMDD-HH:mm:ss")}`;
        this.$refs["edit_form"].clearValidate(['taskTitle']);
      }
    },
    // 将子组件获取的禁飞区传递出来
    addallairArray(value) {
      this.allairArray = value
    }
  },
  mounted() {
    this.FlightLine();
    this.drd_task_type();
    this.drd_task_cate();
    this.drd_uav_type();
    this.drd_mount_type();
    this.getAllOrganization();
  },
//   inject: ["toggle_mode"],
  watch: {
    "edit_form.date1"(val) {
      // 监视预计飞行开始时间做的某些事
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (date > today) {
          // 如果选的日期不是和当天同一天
          this.pickerOptionsStart.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsStart.selectableRange = `${this.getNowTime()} - 23:59:59`;
        }
        if (
          new Date(this.edit_form.date1).getHours() != new Date().getHours() &&
          new Date(this.edit_form.date1).getDay() == new Date().getDay() &&
          new Date(this.edit_form.date1).getDate() == new Date().getDate() &&
          new Date(this.edit_form.date1).getHours() == 0
        ) {
          this.edit_form.date1 = new Date();
        }
      }
      if (
        this.edit_form.date2 &&
        val &&
        new Date(val).getTime() > new Date(this.edit_form.date2).getTime()
      ) {
        this.edit_form.date2 = null; // 如果大于结束时间，清空结束时间
      }
      if (this.cahgeDay1 == 0) {
        this.cahgeDay1 = 1;
      } else {
        this.edit_form.workDate = [{ start: null, end: null }];
      }
    },
    "edit_form.date2"(val) {
      // 监视预计飞行结束时间做的某些事
      if(val && new Date(val).getTime() < new Date(this.edit_form.date1).getTime()){
        this.edit_form.date2 = this.edit_form.date1
        return
      }
      if (val) {
        const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
        let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        if (
          this.edit_form.date1 &&
          new Date(this.edit_form.date1).getTime() > Date.now()
        ) {
          today = new Date(
            new Date(this.edit_form.date1).setHours(0, 0, 0, 0)
          ).getTime();
        }
        if (date > today) {
          // 如果选的日期不是和开始时间或是当天同一天
          this.pickerOptionsEnd.selectableRange = "00:00:00 - 23:59:59";
        } else {
          // 如果是同一天
          this.pickerOptionsEnd.selectableRange = `${this.getNowTime(
            this.edit_form.date1
          )} - 23:59:59`;
        }
        if (
          new Date(val).getHours() <new Date(this.edit_form.date1).getHours() &&
          new Date(val).getDay() == new Date(this.edit_form.date1).getDay() &&
          new Date(val).getDate() == new Date(this.edit_form.date1).getDate()
        ) { // 如果和开始日期同一天，点击的时候让它是开始日期
          this.edit_form.date2 = new Date(this.edit_form.date1);
        }
      }
    },
    workDateCopyc: {
      // 监视详细作业时间变化
      deep: true,
      handler: function (newVal, oldVal) {
        let starIndex = -1;
        let endIndex = -1;
        if (newVal.length == oldVal.length) {
          newVal.forEach((item, index) => {
            if (item.start != oldVal[index].start) {
              starIndex = index;
            }
            if (item.end != oldVal[index].end) {
              endIndex = index;
            }
          });
        }
        if (starIndex != -1) {
          if (newVal[starIndex].start) {
            const date = new Date(
              new Date(newVal[starIndex].start).setHours(0, 0, 0, 0)
            ).getTime();
            const today1 = new Date(
              new Date(this.edit_form.date1).setHours(0, 0, 0, 0)
            ).getTime();
            const today2 = new Date(
              new Date(this.edit_form.date2).setHours(0, 0, 0, 0)
            ).getTime();
            if (date != today1 && date != today2) {
              // 如果选的日期不是和当天同一天
              this.detailsPickerOptionsStart.selectableRange =
                "00:00:00 - 23:59:59";
            } else if (date == today1) {
              // 如果是同一天
              this.detailsPickerOptionsStart.selectableRange = `${this.getNowTime(
                this.edit_form.date1
              )} - 23:59:59`;
            } else if (date == today2) {
              this.detailsPickerOptionsStart.selectableRange = `00:00:00 - ${this.getNowTime(
                this.edit_form.date2
              )}`;
            }
            if (
              new Date(newVal[starIndex].start).getHours() <
                new Date(this.edit_form.date1).getHours() &&
              new Date(newVal[starIndex].start).getDay() ==
                new Date(this.edit_form.date1).getDay() &&
              new Date(newVal[starIndex].start).getDate() ==
                new Date(this.edit_form.date1).getDate()
            ) {
              this.edit_form.workDate[starIndex].start = new Date(
                this.edit_form.date1
              );
            }
            if (
              new Date(newVal[starIndex].start).getHours() >
                new Date(this.edit_form.date2).getHours() &&
              new Date(newVal[starIndex].start).getDay() ==
                new Date(this.edit_form.date2).getDay() &&
              new Date(newVal[starIndex].start).getDate() ==
                new Date(this.edit_form.date2).getDate()
            ) {
              this.edit_form.workDate[starIndex].start = new Date(
                this.edit_form.date2
              );
            }
          }
          if (
            this.edit_form.workDate[starIndex].end &&
            newVal[starIndex].start &&
            new Date(newVal[starIndex].start).getTime() >
              new Date(this.edit_form.workDate[starIndex].end).getTime()
          ) {
            this.edit_form.workDate[starIndex].end = null; // 如果大于结束时间，清空结束时间
          }
        }
        if (endIndex != -1) {
          if (newVal[endIndex].end) {
            const date = new Date(
              new Date(newVal[endIndex].end).setHours(0, 0, 0, 0)
            ).getTime();
            let day1 = this.edit_form.date1;
            if (this.edit_form.workDate[endIndex].start) {
              day1 = this.edit_form.workDate[endIndex].start;
            }
            const today1 = new Date(
              new Date(day1).setHours(0, 0, 0, 0)
            ).getTime();
            const today2 = new Date(
              new Date(this.edit_form.date2).setHours(0, 0, 0, 0)
            ).getTime();
            if (date != today1 && date != today2) {
              // 如果选的日期不是和当天同一天
              this.detailsPickerOptionsEnd.selectableRange =
                "00:00:00 - 23:59:59";
            } else if (date == today1) {
              // 如果是同一天
              this.detailsPickerOptionsEnd.selectableRange = `${this.getNowTime(
                day1
              )} - 23:59:59`;
            } else if (date == today2) {
              this.detailsPickerOptionsEnd.selectableRange = `00:00:00 - ${this.getNowTime(
                this.edit_form.date2
              )}`;
            }
            if (
              new Date(newVal[endIndex].end).getHours() <
                new Date(day1).getHours() &&
              new Date(newVal[endIndex].end).getDay() ==
                new Date(day1).getDay() &&
              new Date(newVal[endIndex].end).getDate() ==
                new Date(day1).getDate()
            ) {
              this.edit_form.workDate[endIndex].end = new Date(day1);
            }
            if (
              new Date(newVal[endIndex].end).getHours() >
                new Date(this.edit_form.date2).getHours() &&
              new Date(newVal[endIndex].end).getDay() ==
                new Date(this.edit_form.date2).getDay() &&
              new Date(newVal[endIndex].end).getDate() ==
                new Date(this.edit_form.date2).getDate()
            ) {
              this.edit_form.workDate[endIndex].end = new Date(
                this.edit_form.date2
              );
            }
          }
        }
      },
    },
  },
  computed: {
    workDateCopyc() {
      // 深度监视详细作业时间，要先复制一份，不然拿不到旧值
      return JSON.parse(JSON.stringify(this.edit_form.workDate));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .timecss {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .linear-form.vertical .el-form-item .el-form-item__label {
    line-height: 37px;
    float: left;
    text-align: right;
  }
  .launchTask {
    max-height: 590px;
    overflow-y: auto;
  }
    .foot-submit-item{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  .edit-form {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // .el-form-item {
    //   width: calc(50% - 6px);
    // }
    .linear-fields {
      // width: 100%;
      // margin-bottom: 20px;
      border: none;
      // .el-form-item {
      //   width: 100%;
      // }
    }
    .overflow-auto {
      overflow: auto;
      max-height: 130px;
      border: 1px solid #27526e;
    }
    .noselected-area {
      color: #999;
      cursor: pointer;
      line-height: 34px;
      font-size: 16px;
    }
    .selected-area {
      color: rgb(25, 194, 107);
      cursor: pointer;
      line-height: 34px;
      font-size: 16px;
    }
    .Inspection {
      height: 37px;
    }
  }
}
.page-task-create {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  position: relative;
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
  .area-select-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(12, 34, 67, 0);
    z-index: 7000;
    .content {
      position: absolute;
      width: 1600px;
      height: 800px;
      left: 50%;
      top: 100px;
      margin-left: -800px;
      border: 1px solid #009aff;
      .button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        .el-button--primary {
          background-color: #69b3e5;
        }
      }
    }
  }
}
</style>
<style>
.el-scrollbar.el-cascader-menu {
  max-height: 400px;
  overflow-y: auto;
}
.page-task-create .el-cascader {
  width: 100%;
}
</style>
