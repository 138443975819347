<template>
  <div class="cpt-device-ai-booth">
    <div class="selection-box">
      <el-checkbox
        @change="handle_change_checked_all"
        class="device-ai-checked-all"
        v-model="checked_all"
      >
        全选
      </el-checkbox>
    </div>
    <!-- 一行滑动的样式 -->
    <div v-if="type === '0'" class="swiper ai-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide cpt-device-ai"
          v-for="item in dataSource"
          :key="item.id"
          @click="$emit('on-change', item)"
        >
          <el-checkbox
            class="device-ai-checkbox"
            v-model="item.checked"
          ></el-checkbox>
          <span
            class="device-ai-icon iconfont"
            :class="item.icon"
            alt="ai"
          ></span>
          <div class="device-ai-cover">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 三行三列的样式 -->
    <div v-if="type === '1'" class="ai-swiper" style="width: 300px; height: 250px; overflow-y: auto">
      <div class="ai-card-box">
        <div
          class="cpt-device-ai"
          style="height: 75px"
          v-for="item in dataSource"
          :key="item.id"
          @click="$emit('on-change', item)"
        >
          <el-checkbox
            class="device-ai-checkbox"
            v-model="item.checked"
          ></el-checkbox>
          <span
            class="device-ai-icon iconfont"
            :class="item.icon"
            alt="ai"
          ></span>
          <div class="device-ai-cover">
            {{ item.title }}
        
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 12px" v-if="type === '1'" >
      <div @click="handle_choose_ai()" class="linear-btn">开启应用</div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper.min.css";
import API from "@/api";
import { number } from 'echarts';

export default {
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    deviceHardId: {
      type: String,
      default: () => '',
    },
    ai: {
      type: String,
      default: () => '',
    },
    selection: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: Number | String,
      default: () => '0',
    },
  },
  computed: {
    checked_all: {
      set(value) {
        this.dataSource.map((item) => (item.checked = value));
      },
      get() {
        const { dataSource } = this;
        return dataSource && dataSource.length
          ? dataSource.every((item) => item.checked)
          : false;
      },
    },
  },
  methods: {
    async handle_choose_ai() {
      let obj ={
        ais: [],
        deviceHardId: this.deviceHardId,
      }
      this.dataSource.map((item) => {
        if(item.checked == true){
          obj.ais.push(item.id)
        }
      });
      const res = await API.AI.aiUpdate(obj);
      if(res.status === 1) {
        this.$el_message('修改AI成功');
      } else {
        // this.$message.error('修改AI失败' + res.message);
        this.$message.error('修改AI失败，无人机未在线');
      }

    },
    handle_change_checked_all(value) {
      this.dataSource.map((item) => (item.checked = value));
    },
    get_checked_nodes() {
      return this.dataSource.filter((item) => item.checked);
    },
  },
  watch: {
    ai:{
      handler: function(value) {
        if(value){
          let aiArr= value.split(',')
          aiArr.forEach(element => {
             this.dataSource.map(item=>{
               if(item.title == element){
                 item.checked = true
               }
             })
          });
        }
      },
      deep:true,
      immediate: true,
    },
    dataSource:{
      handler(value) {
      },
      deep:true,
      immediate: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".ai-swiper", {
        slidesPerView: "auto",
        // centeredSlides: true,
        spaceBetween: 5,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-ai-booth {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .el-checkbox {
      width: 14px;
      height: 14px;
      box-sizing: border-box;
      .el-checkbox__input {
        box-sizing: border-box;
        .el-checkbox__inner {
          background: transparent;
          border: 1px solid #cce8ff;
          border-radius: 14px;
          &::after {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            position: absolute;
            left: 50%;
            top: 50%;
            box-sizing: border-box;
            overflow: hidden;
          }
        }
      }
      .el-checkbox__label {
        font-size: 14px;
        color: #cce8ff;
      }
      &.is-checked {
        .el-checkbox__input {
          box-sizing: border-box;
          .el-checkbox__inner {
            background: transparent;
            &::after {
              background: #cce8ff;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .selection-box {
    margin-bottom: 8px;
  }

  .ai-swiper {
    height: 120px;
    width: 100%;
    .cpt-device-ai {
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 92px;
      height: 120px;
      box-sizing: border-box;
      background-image: linear-gradient(
        180deg,
        rgba(50, 117, 222, 0.2) 0%,
        rgba(67, 222, 255, 0.4) 52%,
        rgba(50, 117, 222, 0.2) 100%
      );
      border: 1px solid rgba(132, 178, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .device-ai-checkbox {
        position: absolute;
        left: 4px;
        top: 4px;
        width: 90%;
        height: 90%;
      }
      .device-ai-icon {
        /* width: 80px;
        height: 80px; */
        font-size: 36px;
        color: #cce8ff;
      }
      .device-ai-cover {
        height: 24px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: #9fc4ff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.ai-card-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px
}
</style>