<template>
  <div class="cpt-fn_icons">
    <!-- <Select /> -->
    <!-- 要素图标选择展示哪些类型 -->
    <div class="icons-list_box">
      <!-- 图标 -->
      <div
        v-if="isGjx"
        style="display: flex; flex-wrap: nowrap; justify-content: flex-end"
      >
        <el-tooltip
          :class="item.isShow ? 'show' : 'hide'"
          v-for="item in elementTypeList"
          :key="item.id"
          class="item"
          :content="item.typeName"
          placement="top-start"
        >
          <div class="icon-gjx" @click="stateChange(item)">
            <div class="iconfont" :class="icon[item.id]"></div>
          </div>
        </el-tooltip>
      </div>
      <div class="" @click="isGjx = !isGjx">
        <el-tooltip
          class="item"
          content="全要素"
          placement="left"
          effect="dark"
        >
          <img
            src="./img/1.png"
            style="transform: scale(1.3)"
            class="w50 h50 cp"
            alt=""
            srcset=""
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import FactorItem from "./factor-item";
import Select from "./select";
import API from "@/api";
import Bubble from "@/components/cesium-layer/bubble/index.js";
import Waring from "@/components/cesium-layer/waring/index.js";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import { nanoid } from "nanoid";
import js from "@turf/polygon-to-line";
let bubblesList = [];
let tagging = [];
export default {
  components: { FactorItem, Select },
  data() {
    return {
      elementTypeList: [],
      isGjx: false,
      elementList: [],
      tagging: null,
      baseUrl: null,
      treeList: [],
      icon: {
        29: "icon-zhifajiluyi",
        30: "icon-duijiangji",
        31: "icon-shipinjiankong",
        32: "icon-jingwutong",
        33: "icon-zhence",
        25: "icon-yingchao",
        26: "icon-jizhan",
        27: "icon-wurenji1",
      },
      height: 100000, // 默认相机高度
      iconHeight: 2, // 默认全要素图表高度
      iconList: [],
      time: null,
    };
  },

  watch: {
    "$route.fullPath"(newData) {
      if (newData == "/home?id=1" || newData == "/new/flightManagement") {
        // 展示图标
        this.entitiesState(true);
      } else {
        // this.isGjx = false
        setTimeout(() => {
          this.entitiesState(false);
          bubblesList.forEach((element) => {
            element.windowClose();
          });
        }, 1000);
      }
    },
    deep: true,
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    setTime() {
      this.time = setInterval(() => {
        if (
          window.plate_data_source &&
          window.plate_data_source.show &&
          bubblesList.length > 0 &&
          this.$route.path == "/home"
        ) {
          bubblesList.forEach((element) => {
            element.windowClose();
          });
        }
      }, 500);
    },
    entitiesState(falg) {
      let IdList = [25, 27];
      for (let index = 0; index < IdList.length; index++) {
        const id = IdList[index];
        this.elementTypeList.forEach((item) => {
          if (item.id === id) {
            item.isShow = falg;
            // 展示该类型图标
            tagging?.entities.values.forEach((item) => {
              if (item.id.split("-")[2] == id) {
                item.show = falg;
              }
            });
          }
        });
      }
    },
    stateChange(data) {
      // 用户点击了要素类型的某个类型
      this.elementTypeList.forEach((item) => {
        if (item.id === data.id) {
          item.isShow = !data.isShow;
          if (data.typeName == "无人机") {
            this.$store.commit("device/SET_JCmodel", item.isShow);
          }
          if (item.isShow) {
            // 展示该类型图标
            tagging?.entities.values.forEach((item) => {
              if (item.id.indexOf(`tagging2-list-${data.id}`) > -1) {
                item.show = true;
              }
            });
          } else {
            // 隐藏该类型图标
            tagging?.entities.values.forEach((item) => {
              if (item.id.indexOf(`tagging2-list-${data.id}`) > -1) {
                item.show = false;
              }
            });
          }
        }
      });
    },
    async getTypeList() {
      // 获取要素类型
      let res = await API.POI.getType({
        pageNum: 1,
        pageSize: 100,
      });
      if (res.data.records) {
        res.data.records.forEach((item) => {
          item.isShow = false;
        });
      }
      this.elementTypeList = res.data.records;
    },
    async async_get_list() {
      let viewer = window.viewer;
      // 获取全要素信息并渲染到地球上
      let { data } = await API.POI.List();
      console.log(data, "async_get_list");
      let list = await API.TASK.Equipment({
        id: "1,2,3",
      });
      console.log(list, "list");
      // const res = await API.DEVICE.organTree();
      // console.log(res, "无人机数据");
      // let wrjList = [];
      // if (res.status == 1) {
      //   this.treeList = res.data[0].devices;
      //   wrjList = this.treeList.filter((item) => {
      //     return item.reportStatus == 1;
      //   });
      // }
      if (list.status === 1) {
        let { baseStations, deviceLocation, onlineDevice } = list.data;
        //全要素
        data.map((item, index) => {
          item.latitude = item.latitude;
          item.longitude = item.longitude;
          item.data = {
            bjd: item.elementName,
            wz: item.location_address,
            data: item.devices,
            typeName: item.type,
            deviceHardId: item.deviceSerial || null,
          };
        });
        // 请求到的基站数据
        if (baseStations) {
          baseStations.map((item) => {
            item.elementName = item.name;
            item.typeId = 26;
            item.ico =
              "/haining/20220928/d463e141-0b2f-4742-8b53-3fcf4b2fa978.png";
            item.data = {
              bjd: item.name,
              wz: item.address,
              bj: item.signalRate,
              typeName: "基站",
            };
          });
          data = [...data, ...baseStations];
        }

        if (deviceLocation) {
          deviceLocation.map((item) => {
            if (item.location_latitude != "NAN") {
              item.elementName = item.locationName;
              item.typeId = 25;
              item.latitude = item.location_latitude;
              item.longitude = item.location_longitude;
              item.ico =
                "/haining/20220928/d627483b-fbaa-48de-8be0-ac353152e514.png";
              item.data = {
                bjd: item.locationName,
                wz: item.location_address,
                data: item.devices,
                deviceHardId: item.deviceSerial,
              };
            }
          });
          data = [...data, ...deviceLocation];
        }
        // // 在线无人机数据
        // let newWrjList = [];
        // if (wrjList) {
        //   newWrjList = wrjList.filter((item) => item.latitude != "nan");
        //   if (newWrjList.length > 0) {
        //     newWrjList.map((item) => {
        //       (item.data = {
        //         deviceName: item.deviceName,
        //         deviceSerial: item.deviceSerial,
        //         taskTitle: item.taskTitle || "暂无",
        //         reportStatus: 1,
        //         online: 1,
        //         typeName: "无人机",
        //       }),
        //         (item.typeId = 27);
        //       item.id = item.deviceHardId;
        //       item.ico =
        //         "/haining/20220928/58a1d0b1-5496-435b-99be-6903fc95000c.png";
        //     });
        //   }
        //   data = [...data, ...newWrjList];
        // }
      } else {
        this.$message.error(list.message);
      }
      this.elementList = data;
      tagging = new Cesium.CustomDataSource("tagging2-list");
      viewer.dataSources.add(tagging);

      data.map((item) => {
        if (!item.longitude && !item.latitude) {
          return;
        }
        const entity = tagging.entities.getById(
          `tagging2-list-${item.typeId}-${item.id}`
        );
        const position = Cesium.Cartesian3.fromDegrees(
          Number(item.longitude),
          Number(item.latitude)
          // this.iconHeight
        );

        if (entity) {
          entity.position = position;
        } else {
          let data = JSON.parse(JSON.stringify(item));
          tagging.entities.add({
            id: `tagging2-list-${data.typeId}-${data.id}`,
            position: position.clone(),
            show: false,
            clampToGround: true, // 这个是线覆盖模型的关键
            zIndex: 100, // 这个是层级，但是必须clampToGround: true
            longitude: {
              type: data.typeId,
              lon: data.longitude,
              lat: data.latitude,
              height: this.iconHeight,
              data: data.data,
            },
            label: {
              // text: item.elementName,
              font: "normal 16px MicroSoft YaHei",
              style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              outlineColor: Cesium.Color.RED,
              outlineWidth: 2,
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(6, -46),
            },
            billboard: {
              width: 35,
              height: 35,
              image: this.baseUrl + item.ico,
              show: true,
              scale: 1.1,
              // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              // pixelOffset: new Cesium.Cartesian2(0, -45),
              // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
              // // heightReference:Cesium.HeightReference.NONE
              // // heightReference:Cesium.HeightReference.CLAMP_TO_GROUND
            },
            properties: {
              metadata: data,
            },
          });
        }
      });
      if (
        this.$route.fullPath == "/home?id=1" &&
        this.$route.fullPath == "/new/flightManagement"
      ) {
        setTimeout(() => {
          this.entitiesState(true);
        }, 3000);
      } else {
        // if (this.$route.fullPath == "/home?id=2")
        this.entitiesState(false);
      }
      const pinBuilder = new Cesium.PinBuilder();
      const pin50 = pinBuilder
        .fromText("50+", Cesium.Color.PURPLE, 70)
        .toDataURL();
      const pin40 = pinBuilder
        .fromText("40+", Cesium.Color.ORANGE, 60)
        .toDataURL();
      const pin30 = pinBuilder
        .fromText("30+", Cesium.Color.YELLOW, 48)
        .toDataURL();
      const pin20 = pinBuilder
        .fromText("20+", Cesium.Color.GREEN, 48)
        .toDataURL();
      const pin10 = pinBuilder
        .fromText("10+", Cesium.Color.BLUE, 60)
        .toDataURL();

      const singleDigitPins = new Array(8);
      for (let i = 0; i < singleDigitPins.length; ++i) {
        singleDigitPins[i] = pinBuilder
          .fromText(`${i + 2}`, Cesium.Color.VIOLET, 48)
          .toDataURL();
      }
      tagging.clustering.enabled = true;
      tagging.clustering.pixelRange = 15;
      tagging.clustering.minimumClusterSize = 3;
      tagging.clustering.clusterEvent.addEventListener(
        (clusteredEntities, cluster) => {
          cluster.label.show = false;
          cluster.billboard.show = true;
          cluster.billboard.id = cluster.label.id;
          cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;

          if (clusteredEntities.length >= 50) {
            cluster.billboard.image = pin50;
          } else if (clusteredEntities.length >= 40) {
            cluster.billboard.image = pin40;
          } else if (clusteredEntities.length >= 30) {
            cluster.billboard.image = pin30;
          } else if (clusteredEntities.length >= 20) {
            cluster.billboard.image = pin20;
          } else if (clusteredEntities.length >= 10) {
            cluster.billboard.image = pin10;
          } else {
            cluster.billboard.image =
              singleDigitPins[clusteredEntities.length - 2];
          }
        }
      );
    },
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.height = height;
      }, Cesium.ScreenSpaceEventType.WHEEL);

      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );

        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );

          // 判断点击位置是否等于渲染全要素并且没有创建弹框
          if (
            tagging?.entities.values.indexOf(pickedLabel.id) > -1 &&
            !document.getElementById(pickedLabel.id.id)
          ) {
            let id = pickedLabel.id.id;
            this.bubble(id);
          } else {
            if (this.bubbles) {
              this.bubbles.windowClose();
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // 悬浮图标效果
      // handler.setInputAction((movement) => {
      //   let list =tagging?.entities.values.filter(item => item.show === true)
      //   let pickedObject = viewer.scene.pick(movement.endPosition);
      //   if (viewer.scene.pickPositionSupported && Cesium.defined(pickedObject) && list.includes(pickedObject.id) &&  !document.getElementById(pickedObject.id.id)) {
      //     this.waring(pickedObject.id.id)
      //   } else {
      //     if(this.warings){
      //       this.warings.windowClose()
      //     }
      //   }
      // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    },
    bubble(id) {
      let aa = tagging?.entities.values.filter((item) => item.id === id);
      this.bubbles = new Bubble(
        Object.assign(aa[0], {
          viewer,
        })
      );
      let b = this.bubbles;
      bubblesList.push(b);
    },
  },

  async mounted() {
    this.setTime();
    await this.getTypeList();
    this.init_events();
    if (
      this.$route.fullPath == "/home?id=1" &&
      this.$route.fullPath == "/new/flightManagement"
    ) {
      this.async_get_list();
      // setTimeout(() => {
      //   this.entitiesState(true)
      // }, 500);
    } else {
      this.async_get_list();
      setTimeout(() => {}, 1500);
    }
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },
};
</script>

<style lang="scss">
.icons-list_box {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .iconfont {
    font-size: 24px !important;
    color: #018fc1 !important;
    margin-top: -2px;
    cursor: pointer;
  }

  .hide {
    .iconfont {
      color: #ddd !important;
      cursor: pointer;
    }
  }

  .item {
    /* width: 44px;
        height: 44px; */
    /* background: url('./img/bg.png'); */
    margin-left: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-gjx {
      margin-top: -4px;
      /* padding-left: 4px; */
      width: 30px;
      height: 30px;
    }
  }
}

.cpt-fn_icons {
  // position: absolute;
  // top: 262px;
  // right: 550px;
  position: absolute;
  top: 20px;
  right: 0;

  .icons-list_box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 5px;
  }
}

.defaultFlag {
  margin-top: 10px;
  right: 50px;
  transition: 0.3s;
}

.icon-gjx {
  width: 48px;
  height: 48px;
  background: rgba(6, 25, 56, 0.7);
  border: 1px solid #007aff;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.7;
  }
}
</style>
