<template>
    <el-dialog
      title="安全飞行注意事项"
      :visible.sync="securityFlyVisible"
      width="605px"
      height="320px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="sy">
            <!-- <p>1、注意飞行时候无人机姿态角（俯仰角、横滚角、偏航角）当角度过大时候用提高警惕。</p>
            <p>2、注意飞机电流个电压变化是否变化过大，影响飞机供电系统和返航时能否安全回来。</p>
            <p>3、注意飞机的高度是否出现变化。</p>
            <p>4、注意飞机图传中飞机轨迹是否与飞行航线是否一致。</p>
            <p>5、注意航线周围的高建筑物，当遇到高于无人机高度时候，不要轻易改变镜头和航线模式（特殊要求除外）。</p>
            <p>6、关注推流视频，防止出现卡顿现象，及时刷新。</p> -->
            <!-- ${index+1+'、'} -->
            <p v-for="(item) in data" :key="item.lightInfoId">{{`${item.detail}`}}</p>
      </div>
      
    </el-dialog>
</template>
<script>
import API from '@/api'
export default {
  props: {
    securityFlyVisible: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      securityList:[],
      data:[]
    };
  },
  methods: {
    handleClose() {
      this.clear()
    },
    async securityFly() {
      let res = await API.SECURITYFLY.Security(3);
      this.securityList = res.data;
    },
  },
 async created(){
   let res = await API.FLYCOM.latest(1,1000)
     console.log(res);
    try {
      if (res.status==1) {
      this.data=res.data.records
    }else{
      this.$message.error(new Error(res.message))
    }
    } catch (error) {
      this.$message.error(new Error(res.message))
    }
  }
};
</script>
<style lang="scss" scoped>

::v-deep .sy{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9FC4FF;
    line-height: 28px;
    max-height: 320px;
    overflow: auto;
}

</style>