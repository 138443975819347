<template>
  <div
    :id="id"
    class="box"
    :style="data.type === 25 ? 'width: 320px' : 'width: 200px'"
  >
    <div :class="data.type === 25 ? 'pine1' : 'pine'"></div>
    <div
      class="box-wrap"
      :style="data.type === 25 ? 'height: 210px; left:34%' : 'height: 163px'"
    >
      <div class="close" @click="closeClick">X</div>
      <div class="area">
        <div class="area-title fontColor">{{ showType(data.type) }}</div>
      </div>
      <div class="content">
        <div v-if="data.type === 26">
          <div class="data-li">
            <div class="data-label textColor">基站布局点：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bjd }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">位置：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.wz }}</span>
            </div>
          </div>
          <div class="data-li">
            <div class="data-label textColor">辐射半径：</div>
            <div class="data-value">
              <span class="label-num yellowColor">{{ data.data.bj }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicLabel",
  data() {
    return {
      show: true,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    id: {
      type: String,
      default: "001",
    },
    state: {
      type: String,
      default: "001",
    },
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeClick() {
      if (this.closeEvent) {
        this.closeEvent();
      }
    },
    showType(id){
      if (id==25) {
        return '派出所'
      } else if(id==26) {
        return '基站'
      }else if(id==27){
        return '无人机'
      }
    }
  },
  mounted() {
  },
};
</script>


<style lang="scss" scoped>
.box {
  width: 200px;
  position: relative;
  bottom: 0;
  left: 0;
  ::v-deep {
    .pine {
    position: absolute;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    line-height: 120px;
    text-indent: 5px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -83px;
      width: 40%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid #38e1ff;
      transform-origin: bottom center;
      transform: rotateZ(135deg) scale(1.5);
      animation: slash 0.5s;
      filter: drop-shadow(1px 0px 2px #03abb4);
    }
  }

  .pine1 {
    position: absolute;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    line-height: 120px;
    text-indent: 5px;
    &::before {
      content: "";
      position: absolute;
      left: 56px;
      bottom: -83px;
      width: 40%;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid #38e1ff;
      transform-origin: bottom center;
      transform: rotateZ(135deg) scale(1.5);
      animation: slash 0.5s;
      filter: drop-shadow(1px 0px 2px #03abb4);
    }
  }
  .box-wrap {
    position: absolute;
    left: 21%;
    top: 0;
    width: 100%;
    // height: 163px;
    border-radius: 50px 0px 50px 0px;
    border: 1px solid #38e1ff;
    background-color: #38e1ff4a;
    box-shadow: 0 0 10px 2px #29baf1;
    animation: slide 2s;
    .close {
      position: absolute;
      color: #fff;
      top: 1px;
      right: 10px;
      text-shadow: 2px 2px 2px #022122;
      cursor: pointer;
      animation: fontColor 1s;
    }
    .area {
      position: absolute;
      top: 20px;
      right: 0;
      width: 95%;
      height: 30px;
      background-image: linear-gradient(to left, #4cdef9, #4cdef96b);
      border-radius: 30px 0px 0px 0px;
      animation: area 1s;

      &-title {
        text-align: center;
        line-height: 30px;
      }
      .fontColor {
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }
    }

    .textColor {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      text-shadow: 1px 1px 5px #002520d2;
      animation: fontColor 1s;
    }
    .yellowColor {
      font-size: 14px;
      font-weight: 600;
      color: #f09e28;
      text-shadow: 1px 1px 5px #002520d2;
      animation: fontColor 1s;
    }
  }
  }
  
}

.content {
  padding: 55px 10px 10px 10px;
  .data-li {
    display: flex;
  }
}

@keyframes fontColor {
  0% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  40% {
    color: #ffffff00;
    text-shadow: 1px 1px 5px #00252000;
  }
  100% {
    color: #ffffff;
    text-shadow: 1px 1px 5px #002520d2;
  }
}

@keyframes slide {
  0% {
    border: 1px solid #38e1ff00;
    background-color: #38e1ff00;
    box-shadow: 0 0 10px 2px #29baf100;
  }

  100% {
    border: 1px solid #38e1ff;
    background-color: #38e1ff4a;
    box-shadow: 0 0 10px 2px #29baf1;
  }
}
@keyframes area {
  0% {
    width: 0%;
  }
  25% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

@keyframes slash {
  0% {
    transform: rotateZ(135deg) scale(0);
  }

  100% {
    transform: rotateZ(135deg) scale(1.5);
  }
}
</style>
<style lang="scss" scoped>
 ::v-deep .Location {
  .el-table {
    color: #fff;
  }
  .el-table th.el-table__cell {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-table tr {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-table,
  .el-table__expanded-cell {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: rgba(0, 0, 0, 0);
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .el-table::before {
    height: 0;
  }
  .el-table thead {
    color: #fff;
  }
}
</style>