<template>
  <LivePlayer
    class="livenvr-player"
    ref="liveqing-player"
    :videoUrl="video.vUrl"
  />
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  components: { LivePlayer },
  data() {
    return {
      video: {
        vUrl: "",
      },
    };
  },
  watch: {
    dataSource: {
      handler(value) {
        // console.log("valuevalue:",  value);
        if (value && value.length) {
          this.video = value.find(item => item.vUrl.indexOf("webrtc") !== -1);
          this.$nextTick(() => {
            this.$refs["liveqing-player"].play();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.livenvr-player {
  width: 100%;
  height: 100%;
}
.vjs-volume-panel,
.vjs-playback-rate,
.live-player-stretch-btn,
.vjs-time-divider,
.vjs-duration,
.vjs-live-control {
  display: none !important;
}
</style>