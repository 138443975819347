<template>
  <div class="cpt-layout">
    
    <CreateTask v-if="createShow" :createShow="createShow" :clear="() => createShow = false" />
    <div
      class="floating-menu"
      :class="[
        qysFlag ? 'qysStyleRight' : 'qysStyleLeft',
        offset ? 'offset' : '',
      ]"
      :style="styleContent"
    >
      <!-- <div class="header" v-move></div> -->
      <FactorIcons v-show="true" />
      <CesiumToolbar
        @toolbar-click="toolbar_handle_click"
        @on-dqdb-open="()=>{this.contrastStates = true}"
        ref="cesium_toolbar"
      />
      
      <!-- 三维模型要展示的东西 -->
      <div class="three-d">
        <slot name="alertMessage" />
        <!-- 发起需求 -->
        <div class="" @click="createShow = true" v-if="createIcon">
            <el-tooltip  content="发起需求" placement="right">
                  <img   style="transform: scale(1.3);"  class="w50 mt3 h50 cp" src="./img/1.png">
            </el-tooltip>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
let modelList = [];
// 已废弃
// import FlightSimulation from "./flightSimulation"; // 飞行模拟弹框
// import RouteSimulation from "./flightSimulation/flightParameterSimulation.vue"; // 飞行参数模拟弹框
// import CreateLine from "./createLine"; // 创建（修改）模拟弹框
// import ContrastPopup from "./constrast"; // 多期对比弹框
// import AirspaceMPopup from "./airspaceManagement"; // 空域管理弹框
// import CreateAirSpace from "./airspaceManagement/createAirSpace.vue"; // 空域管理弹框
// import TaskList from "./threeDList/list.vue";  // 三维列表搜索框里的东西
// import Civiluav from "./civiluav/index.vue";  // 民用无人机
// import dayjs from "dayjs";
// import FiexdLeft from "@/components/fiexdleft"; // 右上角位置搜索
// import { mapGetters } from "vuex";
// import API from "@/api";
// import Lockr from "lockr";
// import SafeFly from "@/components/header-right/safeFly.vue";
// import Water from "@/components/accident/water";
// import HKVideo from "@/components/hk-video";
// import reportPopup from "./report-popup";
// import NotificationsBar from "@/components/notificationsBar";
// import Message from "@/components/header-right/message.vue";
// import data from "./data";
import methods from "./methods";
import Popup from "./methods/popup";
import CreateTask from "./createTask"
import FactorIcons from "@/components/factor-icons_v3";
import CesiumToolbar from "@/components/cesium-toolbar";
let collection = {
  zysb: null,
  jwys: null,
  gzsb: null,
  jlys: null,
  kfq: null,
  zddq: null,
};
export default {
  components: {
    FactorIcons,
    CesiumToolbar,
    CreateTask
  },
  props: {
    // qysFlag: {
    //   type: Boolean,
    // },
    gbwy:{ // 控制首页右上角位置搜索的东西随着数据面板的展示而移动
      typeof:Boolean,
      default:false
    },
    styleContent:{
      typeof:Object,
      default:null
    }
  },
  data() {
    return {
      qysFlag:true,
      offset: false,
      createIcon: false,// 控制发起需求图标
      baseUrl:null,
      createShow: false,// 控制发起需求弹框
      createIcon: false,// 控制发起需求图标
      FlyVisible: false,
    };
  },
  methods: {
   
    ...methods,
    handle_report_show(e) {
      this.report_is_show = e;
      if (!e) {
        this.FlyVisible = true;
        this.$refs.SafeFlyRef && this.$refs.SafeFlyRef.securityFly();
      }
    },
    click_billboard() {
      // let viewer = this.cesium_layer.viewer();
      let viewer = window.viewer;
      let scene = viewer.scene;
      const _this = this;

      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (pickedLabel.id.name == "area") {
            try {
              let data = JSON.parse(pickedLabel.id.id);
              let arr = _this.move_id_arr;
              if (arr.indexOf(data.id) < 0) {
                _this.move_id_arr.push(data.id);
                // 调用弹窗方法
                let popup = new Popup({
                  viewer: viewer,
                  geometry: cartesian,
                  data: data,
                  arr: _this,
                });
              }
            } catch (error) {}
          } else {
            try {
              let data = JSON.parse(pickedLabel.id);
              let arr = _this.zdys_id_arr;
              if (arr.indexOf(data.id) < 0) {
                _this.zdys_id_arr.push(data.id);
                if (cartesian && data?.entityType == "zdys") {
                  // 调用弹窗方法
                  let popup = new Popup({
                    viewer: viewer,
                    geometry: cartesian,
                    data: data,
                    del_real: this.del_real,
                    arr: _this,
                  });
                }
                if (data?.category === 1) {
                  this.start_timer(data);
                }
              }
            } catch (error) {}
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
  },
  computed: {
   
  },
  watch: {
    "$store.state.device.flag": {
      handler(value) {
        console.log(value);
        this.qysFlag = value;
      },
      deep: true,
    },
    "$store.state.device.waterbox"(newData) {
      this.waterebox = newData;
    },
    
  },
  provide() {
    return {
      handle_report_show: this.handle_report_show,
    };
  },
  async mounted() {
    this.baseUrl = process.env.VUE_APP_WS_IP;
    // 禁飞区
    if (this.$route.path === "/home") {
      this.createIcon = true
      let cardStatus = localStorage.getItem("cardStatus");
      if (cardStatus) {
        this.handle_report_show(true);
      }
      
    }
    if (this.$route.path === "/platform/model") {
      this.threeDIsShow = true
    }

    let { cesium_layer } = this;
    let router_view = this.$refs["router-view"];

    // await cesium_layer.init();

    router_view?.cesium_layer_change_mode &&
      (await router_view.cesium_layer_change_mode(
        cesium_layer.fly_to_by_mode,
        cesium_layer.MODES
      ));

    // await this.uav_list()

    // this.click_billboard();
    // this.move_billboard();
    // this.click_hk_video();

    // 关闭地图深度检测
    // let viewer = cesium_layer.viewer();
    let viewer = window.viewer;

    viewer.scene.globe.depthTestAgainstTerrain = false;
    // this.addAirSpace()
   
  },
  beforeDestroy() {
    // this.destroy_timer();
  },
};
</script>

<style lang="scss" scoped>
.cpt-layout {
  box-sizing: border-box;
  overflow: hidden;
  .consolecopy{
    position: fixed;
    z-index: 100;
    top: 170px;
    left: 40px;
    // width: 316px;
    width: 500px;
    height: 642px;
    // padding: 14px 0px;
    padding-top: 20px;
    border: solid 1px #0097FB;
    background: rgba(8, 37, 79, 0.8);
    .title{
      position: absolute;
      left: 0;
      top: -20px;
      width: 100%;
      text-align: center;
      width: 60%;
      height: 34px;
      color: #fff;
      display: inline-block;
      margin: 0 auto;
      background-image: radial-gradient(circle at 50% 127%, rgba(0, 161, 255, 0.3) 0%, rgba(17, 31, 57, 0) 100%);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #cce8ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      .close{
        position: absolute;
        top: 4px;
        right: -74px;
        font-size: 28px;
      }
    }
    .list{
      max-height: 300px;
      overflow-y: auto;
      padding: 1px 0px;
      .item{
        color: #9FC4FF;
        margin-bottom: 6px;
        border: 1px solid rgba(0, 154, 255, 0.8);
        position: relative;
        box-sizing: border-box;
        width: 98%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        .img{
          position: absolute;
          top: 0px;
          right: 20px;
        }
      }
    }
  }
  .cpt-layout-alarm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .cpt-layout-close {
    position: absolute;
    top: 2px;
    z-index: 100;
    cursor: pointer;

    color: red;
    font-size: 40px;
    right: 11px;
  }
  .cpt-layout-alarm-hint {
    background: url("~@/assets/images/layout_alarm.png") no-repeat;
    position: absolute;
    width: 489px;
    height: 162px;
    top: 24%;
    left: 50%;
    margin-left: -245px;
    z-index: 100;
    box-sizing: border-box;
    .title {
      font-family: PangMenZhengDao;
      font-size: 24px;
      color: #dce9ff;
      font-weight: 400;
      padding-left: 125px;
      margin-top: 18px;
    }
    .icon-guanbi {
      position: absolute;
      color: #ccc;
      right: 25px;
      top: 12px;
      font-size: 28px;
      cursor: pointer;
    }
    .content {
      text-decoration: none;
      outline: none;
      color: red;
      display: block;
      margin-top: 30px;
      margin-left: 65px;
    }
  }
}

.floating-menu {
  position: fixed;
  top: 40%;
  margin-top: -30px !important;
  right: 14px;
  width: 50px;
  height: 260px;
  text-align: center;
  z-index: 1;
  .three-d{
    width: 50px;
    position: absolute;
    top: 130px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .public{
      width: 50px;
      height: 50px;
      margin-top: 4px;
      transition: 0.2s;
      border: 1px solid #007aff;
      background: rgba(6, 25, 56, 0.7);
      box-sizing: border-box;
      transform: translateX(0);
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        opacity: 0.7;
      }
      & > img {
        width: 20px;
        height: 20px;
        margin: 15px auto;
      }
      .iconfont{
        font-size: 20px !important;
        color: #1ca3ff !important;
        margin: 12px auto;
        cursor: pointer;
      }
    }
  }
  .header {
    display: inline-block;
    margin: 0 auto;
    width: 50px;
    height: 2px;
    border-top: 2px solid #4aadff;
    border-bottom: 2px solid #4aadff;
    background-color: #4aadff;
    padding: 2px 0;
    background-clip: content-box;
    display: inline-block;
  }
  .alertMessage {
    width: 50px;
    height: 50px;
    margin-top: 4px;
    transition: 0.2s;
    border: 1px solid #007aff;
    background: rgba(22, 29, 45, 0.7);
    box-sizing: border-box;
    transform: translateX(0);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      opacity: 0.7;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin: 15px auto;
    }
    .iconfont{
      font-size: 20px !important;
      color: #1ca3ff !important;
      margin: 12px auto;
      cursor: pointer;
    }
  }
}
.qysStyleRight {
  top: 31%;
  right: 420px;
  transition: 0.7s;
}
.qysStyleLeft {
  top: 31%;
  right: 46px;
  transition: 1s;
}
.offset {
  right: 568px;
  transition: 0.1s;
}
.flagpath {
  top: 25% !important;
}
</style>

<style lang="scss">

// 重要元素弹窗
.bx-popup-ctn {
  min-width: 100px;
  position: absolute;
  // z-index: 999;
  padding: 10px;
  opacity: 0.9;
  background: #0c3150;
  border-radius: 8px;
  color: #fff;
  // 20211103修改代码
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #007aff;
  border-radius: 8px;
  &.active {
    background: rgba(192, 98, 30, 0.7);
    border: 1px solid #c0621e;
    .bx-popup-tip {
      background: rgba(192, 98, 30, 0.7);
      border: 1px solid #c0621e;
    }
  }
}
.bx-popup-ctn-yc {
  min-width: 100px;
  position: absolute;
  // z-index: 999;
  padding: 10px;
  opacity: 0.9;
  background: #0c3150;
  border-radius: 8px;
  color: #fff;
  // 20211103修改代码
  background: rgba(73, 14, 14, 0.81);
  // border: 1px solid #04d5da;
  border-radius: 8px;
  &.active {
    background: rgba(192, 98, 30, 0.7);
    border: 1px solid #c0621e;
    .bx-popup-tip {
      background: rgba(192, 98, 30, 0.7);
      border: 1px solid #c0621e;
    }
  }
}
.bx-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  margin-top: 10px;
  overflow: hidden;
  pointer-events: none;
}
.bx-popup-tip {
  width: 17px;
  // background: #0c3150;
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #007aff;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.bx-popup-header-ctn {
  color: #fff;
  font-size: 16px;
  padding: 4px;
  padding-right: 20px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  border-bottom: 1px solid #3b4862;
}
.bx-popup-header-ctn-yc {
  color: #fff;
  font-size: 16px;
  padding: 4px;
  padding-right: 20px;
  color: #ccf5ff;
  letter-spacing: 0;
  font-weight: 700;
  border-bottom: 1px solid #3b4862;
}
.bx-popup-content-ctn {
  padding: 10px 0;
  font-size: 14px;
  .li {
    display: flex;
    margin-bottom: 8px;
    .label {
      color: #afbdd1;
    }
    .hk-link {
      color: #01f5fd;
      cursor: pointer;
    }
  }
}
.el-icon-close {
  color: #8aabd4;
  font-size: 16px;
  position: absolute;
  right: 7px;
  top: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
.toolbar_boxs {
  width: 50px;
  height: 50px;
  margin-top: 4px;
  transition: 0.2s;
  border: 1px solid #007aff;
  background: rgba(6, 25, 56, 0.7);
  box-sizing: border-box;
  transform: translateX(0);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
  & > img {
    width: 20px;
    height: 20px;
    margin: 15px auto;
  }
  .iconfont{
    font-size: 20px !important;
    color: #1ca3ff !important;
    margin: 12px auto;
    cursor: pointer;
  }
}

::v-deep .cpt-hwkeys {
  width: 0;
  height: 0;
}
/* 三位列表样式 */
::v-deep{
  .el-collapse-item__header{
    font-size: 16px;
    font-weight: 600;
    color: #9FC4FF;
  }
  .el-collapse-item__content{
    padding-bottom: 16px;
  }
  .el-collapse-item__header{
    background: rgba(82, 39, 39, 0);
  }
  .el-collapse-item__wrap{
    background: rgba(82, 39, 39, 0);
    border: 0px;
  }
  .el-collapse-item__header{
    border: 0px;
  }
  .el-collapse{
    border: 0px;
  }
  .title{
    .el-icon-close{
      font-size: 22px;
    }
  }
}
.model .cpt-draw{
  height: 100%;
  bottom: 100%;
  top: 0;
  /* position: relative; */
}


.floating-menu {
position: fixed;
top: 50%;
margin-top: -30px !important;
right: 5px;
width: 50px;
height: 260px;
text-align: center;
z-index: 1;
}
.page-task-list {
box-sizing: border-box;
padding: 32px;
height: 100%;
.task-list-notice-wrap {
  display: flex;
  .map-box {
    width: 436px;
    height: 274px;
    border: 1px solid red;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-right: 16px;
  }
  .props-box {
    flex: 1;
    box-sizing: border-box;
    .prop-field {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
      .prop-field-k {
        font-size: 14px;
        color: #cce8ff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
        width: 98px;
      }
      .prop-field-v {
        font-size: 14px;
        color: #cce8ff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
}
</style>
