<template>
  <div
    class="cpt-cesium-layer"
    @mouseenter="CesiumLayerMouseEnter"
    @mouseleave="CesiumLayerMouseLeave"
  >
    <div id="layer-container"></div>
  </div>
</template>

<script>
import { Utils } from "@/lib/cesium";
import jy from "@/data/geo/jy.json";
import { mapGetters } from "vuex";
import { initMap } from "@/utils/cesium.js";
let handler = null;
let entities = null;
export default {
  props: {
    cameraPosition: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  data() {
    return {
      VUE_APP_IP: null,
      entity: null,
      imgUrl: require("./bg2.png"),
      imgx: require("./bg1.png"),
    };
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    console.log("init_viewer", "init_viewer");
    if (this.$route.path === "/home") {
      this.init_plate();
      // 首页初始化不加载地图
      viewer.scene.globe.show = false;

      // 设置自定义背景星空图片
      viewer.scene.skyBox.show = false;
      viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);
    } else {
      viewer.scene.globe.show = true;
    }
  },
  methods: {
    mouseScoll(e) {
      //鼠标滚轮滚动效果
      /*这里获取元素节点*/
      let oPanel = document.getElementById("app");
      let Ripples = document.getElementsByClassName(
        "hwb-mouse-scrolling-effect"
      );
      if (Ripples.length > 0) {
        return;
      }
      let oSpan = document.createElement(`div`);
      /*浏览器兼容*/
      e = e || window.Event;
      /*获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /*给节点赋值参数数值*/
      oSpan.className = "hwb-mouse-scrolling-effect";
      /*在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /*设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /*清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    init_viewer() {
      window.this = this;
      viewer = window.viewer = initMap(this.my_layers_data, "layer-container");
      // let viewer = (window.viewer = new Cesium.Viewer("layer-container", {
      //   animation: false, // 动画控制不显示
      //   shouldAnimate: true,
      //   timeline: false, // 时间线不显示
      //   fullscreenButton: false, // 全屏按钮不显示
      //   creditContainer: document.createElement("div"),
      //   geocoder: false,
      //   homeButton: false,
      //   // maximumRenderTimeChange: Infinity, // 无操作时自动渲染帧率，设为数字会消耗性能，Infinity为无操作不渲染

      //   sceneModePicker: false, // 切换球形和平面型
      //   baseLayerPicker: false,
      //   navigationHelpButton: false,
      //   infoBox: false,
      //   selectionIndicator: false,

      //   requestRenderMode: true, //启用请求渲染模式
      //   scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
      //   sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode

      //   imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
      //     url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
      //     layer: "img",
      //     style: "default",
      //     tileMatrixSetID: "w",
      //     format: "tiles",
      //     maximumLevel: 18,
      //   }),
      //   orderIndependentTranslucency: false,
      //   contextOptions: {
      //     webgl: {
      //       alpha: true,
      //     },
      //   },
      // }));
      viewer.scene.globe.enableLighting = false;
      viewer.shadows = false;
      viewer.scene.sun.show = false;
      //月亮
      viewer.scene.moon.show = false;
      // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 110000
      // 根据环境设置相对应地图
      // if (this.my_layers_data === "night") {
      //   viewer.imageryLayers.addImageryProvider(
      //     new Cesium.UrlTemplateImageryProvider({
      //       url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
      //     })
      //   );
      // } else if (this.my_layers_data === "tian") {
      //   viewer.imageryLayers.addImageryProvider(
      //     new Cesium.UrlTemplateImageryProvider({
      //       url: "https://hawk.hnjcloud.cn/3d/gdm-app/datamg/services/1603165708490304/tile?crs=EPSG:3857&x={x}&y={y}&l={z}",
      //     })
      //   );

      //   viewer.imageryLayers.addImageryProvider(
      //     new Cesium.WebMapTileServiceImageryProvider({
      //       url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
      //       layer: "cia",
      //       style: "default",
      //       tileMatrixSetID: "w",
      //       format: "tiles",
      //       maximumLevel: 18,
      //     })
      //   );
      // }
      // 进入飞行控制页面的时候相机高度低一点
      if (this.cameraPosition) {
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            "112.249138",
            "34.556111",
            93186.753984789
          ),
          orientation: {
            heading: 0.21766716858823276,
            pitch: -0.9699607640658252,
            roll: 6.28318504960658,
          },
        });
      } else {
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            "112.249138",
            "34.556111",
            93186.753984789
          ),
          orientation: {
            heading: 0.21766716858823276,
            pitch: -0.9699607640658252,
            roll: 6.28318504960658,
          },
        });
      }
      viewer.scene.globe.show = false;
      this.init_events();
    },
    // 开启鼠标点击与移动事件
    init_events() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        this.on_plate_left_click(movement);
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.on_plate_wheel(height);
      }, Cesium.ScreenSpaceEventType.WHEEL);
      window.Allhandler = handler;
    },
    on_plate_wheel(height) {
      if (height >= 1.4e7 && !window.plate_data_source) {
        // this.fly_to_plate()
      } else if (height > 1000000) {
        if (this.$route.fullPath == "/home?id=9") {
          return false;
        }
        console.log(this.$route.path, "this.$route.path");
        // 首页板块封面代码
        if (this.$route.path == "/home") {
          try {
            window.plate_data_source.show = true;
            viewer.scene.globe.show = false;
          } catch (error) {}
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              "112.249138",
              "34.556111",
              93186.753984789
            ),
            orientation: {
              heading: 0.21766716858823276,
              pitch: -0.9699607640658252,
              roll: 6.28318504960658,
            },
          });
        }
      }
    },
    fly_to_plate(fly_type = "setView", options) {
      window.plate_data_source && (window.plate_data_source.show = true);
      options = {};
      options.destination = plate_orign;
      options.orientation = plate_hpr;
      options.duration = 5;
      options.complete = () => {
        viewer.scene.skyAtmosphere.show = false;
        viewer.scene.globe.showGroundAtmosphere = false;
        viewer.scene.globe.show = false;
      };
      fly_type === "setView" && options.complete && options.complete();

      this.location(options, fly_type);
      center && (viewer.trackedEntity = center);
    },
    location(options = { destination: origin }, fly_type = "flyTo") {
      viewer.camera[fly_type](options);
    },
    // 左击点击事件
    on_plate_left_click(movement) {
      let data = this.getcameraPosInfo();
      console.log(data, "data");
      let plate = this.get_plate_by_click(movement);
      if (plate) {
        this.fly_to_globe();
      }
    },
    get_plate_by_click(moment) {
      console.log(moment.position);
      let pickedObject = viewer.scene.pick(moment.position);
      if (pickedObject?.id?._name) {
        if (
          pickedObject &&
          pickedObject.id &&
          pickedObject.id._name.indexOf("plate_") != -1
        ) {
          return pickedObject.id;
        } else {
          return null;
        }
      }
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { heading: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样

      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    fly_to_globe() {
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.skyAtmosphere.show = false;
      viewer.scene.globe.showGroundAtmosphere = false;
      viewer.scene.globe.show = true;
      window.plate_data_source.show = false;
      // this.location(options)
    },
    // 设置板块渐变色
    getColorRamp(elevationRamp, isVertical = true) {
      var ramp = document.createElement("canvas");
      ramp.width = isVertical ? 1 : 100;
      ramp.height = isVertical ? 100 : 1;
      var ctx = ramp.getContext("2d");

      var values = elevationRamp;
      var grd = isVertical
        ? ctx.createLinearGradient(0, 0, 0, 100)
        : ctx.createLinearGradient(0, 0, 100, 0);
      grd.addColorStop(values[0], "#6495ED"); //black
      grd.addColorStop(values[1], "#087bff"); //pink
      grd.addColorStop(values[2], "#00dfff"); //blue
      // grd.addColorStop(values[3], '#D33038'); //red
      // grd.addColorStop(values[4], '#FF9742'); //orange
      // grd.addColorStop(values[5], '#ffd700'); //yellow
      // grd.addColorStop(values[6], '#ffffff'); //white

      ctx.fillStyle = grd;
      if (isVertical) ctx.fillRect(0, 0, 1, 100);
      else ctx.fillRect(0, 0, 100, 1);
      return ramp;
    },
    // 加载geo板块
    async init_plate() {
      let promise = Cesium.GeoJsonDataSource.load(jy);
      let dataSource = await promise;
      entities = dataSource.entities.values;

      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];
        entity._name = "plate" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.outlineColor =
          // Cesium.Color.fromCssColorString("#0052c1");
          // entity.polygon.material = this.imgUrl
          entity.polygon.material = this.imgx;
        entity.polygon.extrudedHeight = Math.floor(3600);
        let positions = entity.polygon.hierarchy._value.positions;
        // // 边界线
        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            wgs84.alt += 3600;
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 6,
          material: this.getColorRamp([0.0, 0.045, 0.1], true),
        };
        let center = entity.properties.centroid._value;
        if (entity._name == "plate_北海街道") {
          console.log(center[1], "center[1]", center[0], "center[0]");
          center = Utils.transformWGS842Cartesian({
            lng: center[0] + 0.00585,
            lat: center[1] + 0.0045,
            alt: 3600,
          });
        } else if (entity._name == "plate_沁园街道") {
          console.log(center[1], "center[1]", center[0], "center[0]");
          center = Utils.transformWGS842Cartesian({
            lng: center[0] + 0.007,
            lat: center[1] - 0.01,
            alt: 3600,
          });
        } else if (entity._name == "plate_济水街道") {
          center = Utils.transformWGS842Cartesian({
            lng: center[0] + 0.0052,
            lat: center[1] - 0.0045,
            alt: 3600,
          });
        } else {
          center = Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 3600,
          });
        }
        entity.position = center;

        // // 板块乡镇字体
        entity.label = {
          show: true,
          showBackground: false,
          text: entity._name.split("_")[1],
          backgroundPadding: new Cesium.Cartesian2(1, 3), //设置背景内边距
          font: "8px Source Han Sans CN", //设置文字大小和字体
          fillColor: Cesium.Color.fromCssColorString("#FFF"), //设置文字填充的颜色
          outlineColor: Cesium.Color.BLACK, //设置外框颜色
          outlineWidth: 2, //设置外框大小
          style: Cesium.LabelStyle.FILL_AND_OUTLINE, //填充方式，内外都填充
          scale: 2.0, //设置放大缩小
          verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          // pixelOffset: new Cesium.Cartesian2(-10, -24), //偏移
          pixelOffset: new Cesium.Cartesian2(-10, -30), //偏移
        };
        entity.billboard = {
          show: true,
          image: require("@/assets/images/poi.png"),
          pixelOffset: new Cesium.Cartesian2(-12, -10),
          width: 35,
          height: 35,
        };

        await viewer.dataSources.add(dataSource);
      }
      window.plate_data_source = dataSource;
    },
    setplate() {
      let entities = window.plate_data_source.entities.values;
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];
        // entity.polygon.material = this.img
        entity.polygon.material = "";
        entity.polygon.extrudedHeight = Math.floor(0);
        // // 边界线
        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            wgs84.alt += 0;
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 6,
          material: this.getColorRamp([0.0, 0.045, 0.1], true),
        };
        let center = entity.properties.centroid._value;
        center = Utils.transformWGS842Cartesian({
          lng: center[0],
          lat: center[1],
          alt: 0,
        });

        entity.position = center;
      }
    },
    // 添加预警点
    addWarning(earlyWarningPoint) {
      if (earlyWarningPoint) {
        let data = earlyWarningPoint.split(",");
        if (viewer.entities.getById(earlyWarningPoint)) {
        } else {
          this.entity = new Cesium.Entity({
            id: earlyWarningPoint,
            position: Cesium.Cartesian3.fromDegrees(data[0], data[1], 0),
            point: {
              color: Cesium.Color.RED, //点位颜色
              pixelSize: 12, //像素点大小
            },
            label: {
              text: "预警位置",
              font: "8pt Source Han Sans CN", //字体样式
              fillColor: Cesium.Color.RED, //字体颜色
              backgroundColor: Cesium.Color.AQUA, //背景颜色
              showBackground: true, //是否显示背景颜色
              style: Cesium.LabelStyle.FILL, //label样式
              outlineWidth: 2,
              outline: true,
              verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT, //水平位置
              pixelOffset: new Cesium.Cartesian2(-28, -24), //偏移
            },
          });
          viewer.entities.add(this.entity);
        }
        viewer.camera.flyTo({
          // 设置相机位置
          destination: Cesium.Cartesian3.fromDegrees(data[0], data[1], 10000),
        });
      }
    },
  },
  beforeDestroy() {
    this.CesiumLayerMouseLeave();
  },
  watch: {
    my_layers_data: function (newVal, oldVal) {
      if (newVal != oldVal) {
        viewer.imageryLayers.removeAll();
        if (newVal === "night") {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
            })
          );
        } else if (newVal === "tian") {
          // //高德影像地图数据图层，自带注记
          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "img",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );

          viewer.imageryLayers.addImageryProvider(
            new Cesium.WebMapTileServiceImageryProvider({
              url: "https://t0.tianditu.gov.cn/cia_w/wmts?tk=16d91f7e1526ee1e3fe10303915f9ebb",
              layer: "cia",
              style: "default",
              tileMatrixSetID: "w",
              format: "tiles",
              maximumLevel: 18,
            })
          );
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider({
              url: "https://hawk.hnjcloud.cn/3d/gdm-app/datamg/services/1603165708490304/tile?crs=EPSG:3857&x={x}&y={y}&l={z}",
            })
          );
        }
      }
    },
    "$route.path"(newData) {
      if (newData != "/home") {
        viewer.scene.globe.show = true;
      }
    },
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.cpt-cesium-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #layer-container {
    background: url("~@/assets/home/bg.gif") no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
