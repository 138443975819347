<template>
  <div>
    <Dialog title="三维模型申请" v-if="ModelVisible" @on-close="closeIDsPopup" :width="951">
         <!-- 头部 -->
            <div class="Model_Header">
              <div class="Header_Right" @click="openApplication">
                <img class="icon" src="~@/assets/img/3D-shangchuan.png" alt=""> 申请记录
              </div>
            </div>
        <Table :cellName="cellName"
         :dataVal="dataVal"
          :pagination="pagination"
           @onChangePage="(page)=>{
            pagination.pageNo=page;
            async_get_list()
           }"
           >
           <template #organizationName>
                <el-tooltip
                 effect="dark"
                 :open-delay="300"
                 content="机构名称"
                 placement="top"
                 popper-class="my-tooltip"
                 >
                <span>济云智慧</span>
                </el-tooltip>
            </template>
            <template #timeFilter="{ row }">
                <!-- <el-tooltip
                 effect="dark"
                 :open-delay="300"
                 :content="`${row.item.startTime}-${row.item.endTime}`"
                 placement="top"
                 popper-class="my-tooltip"
                 v-if="row.item.startTime && row.item.endTime"
                 >
                    <div>{{`${row.item.startTime}-${row.item.endTime}`}}</div>
                </el-tooltip> -->
                <el-tooltip
                 effect="dark"
                 :open-delay="300"
                 content="点击编辑:开始时间-结束时间"
                 placement="top"
                 popper-class="my-tooltip"
                 >
                 <!-- 如果需要在scoped情况下去更改日历组件样式，需要关闭自动挂载到Body元素上面 -->
                    <el-date-picker
                        v-model="dataUpdate[row.index]"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        size="mini"
                        :style="{width:row.width}"
                        popper-class="popperClass"
                        >
                        </el-date-picker>
                </el-tooltip>
            </template>
            <template #action="{ row }">
                <el-tooltip
                 effect="dark"
                 :open-delay="300"
                 content="一键申请"
                 placement="top"
                 popper-class="my-tooltip"
                 >
                <img class="icon" style="vertical-align: middle;cursor: pointer;" src="~@/assets/img/3D-shenqin.png" @click="apply(row)" />
                </el-tooltip>
            </template>
        </Table>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/new-dialog/index.vue'
import Table from './table.vue'
import API from '@/api'
import dayjs from 'dayjs'
import {mapGetters} from 'vuex'
export default {
    components:{Dialog,Table},
    props:{
        ModelVisible:{
            typeof:Boolean,
            default:false
        }
    },
    data() {
        return {
            cellName: [
            {value:'organizationName',name:"机构名称",slot:true},
            {value:'name',name:"服务名称"},
            {value:'type',name:"数据类型"},
            {value:'addtime',name:"时间",width:'180px'},
            {value:'timeFilter',name:"时间设定",width:'200px',slot:true},
            {value:'action',name:"操作",width:'100px',slot:true}
            ],//表头内容
            dataVal:[],//表格内容
            pagination:{
                pageNo:1,
                pageSize:10,
                total:0
            }, //分页数据
            dataUpdate:[],//时间
        };
    },
    computed:{
        ...mapGetters(['user_info_hn']) 
    },
    methods:{
        openApplication(){//打开申请记录弹框，关闭三维申请列表
            this.$emit('openApplication', true);
        },
       async apply(row){ //一键申请
    //    console.log(dayjs(this.dataUpdate[row.index][0]).format("YYYY-MM-DD HH-MM-ss"));
    //         console.log(row.item);
            if (this.dataUpdate[row.index]) {
                let params = {
                 dataId:row.item.id
                }
              params.startTime = dayjs(this.dataUpdate[row.index][0]).format("YYYY-MM-DD HH:mm:ss")
              params.endTime = dayjs(this.dataUpdate[row.index][1]).format("YYYY-MM-DD HH:mm:ss")
              //申请通过
            let res = await API.DEVICE.requestServiceData(params)
            if (res.code==200) {
                 
                this.$message({type:'success',message:'申请成功'})
                this.dataUpdate[row.index]=null
                this.async_get_list()
            } else {
                this.$message.error(res.message)
            }

            }else{
               this.$message({type: 'warning',message:'时间为空，请选择时间!'})
            }
        },
        closeIDsPopup(){//关闭弹框
            this.$emit('CloseModel', false);
        },
       async async_get_list(){ //获取列表数据
       const { pageNo , pageSize } = this.pagination
       let obj = { pageNo , pageSize }
       for (let index = 0; index < pageSize; index++) {
        this.dataUpdate.push(null)
       }
            let res = await API.DEVICE.serviceDatalList(obj)
             
            if (res.code==200) {
                this.dataVal=res.data.records
                this.pagination.total=res.data.total
            }else{
                this.$message.error(res.message)
            }
        }
    },
    created(){
        this.async_get_list()
        // if (this.user_info_hn.departmentId!=60) {
        //     this.cellName.push()
        //     this.cellName.push()
        // }
    }
}
</script>

<style scoped lang="scss">
.Model_Header{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: -20px;
          height: 40px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          &>.Header_Right{
            display: flex;
            align-items: center;
            width: 80px;
            justify-content: space-between;
            cursor: pointer;
            &>.icon {
                   vertical-align: middle;
                 }
          }
        }
::v-deep {
    .el-range-editor.el-input__inner{
        position: relative;
        background-color: transparent;
        border: 1px solid transparent;
        &>.el-range-separator{
            color: #fff;
        }
        &>.el-range-input{
            color: #fff;
        }
    }
    .el-range-editor--mini .el-range__close-icon, .el-range-editor--mini .el-range__icon{
        color: #fff;
    }

}

</style>
<style lang="scss">
        //日历弹出
  .popperClass.el-picker-panel {
    // position: absolute !important;
    // left: 0 !important;
    // top: 30px !important;
    background: rgba(21,95,192,0.1) !important;
    border: 1px solid #73A6D1 !important;
    color: #B6D4FF;
    backdrop-filter: blur(4px);
    //头部
    .el-date-range-picker__time-header{
        border-bottom: 1px solid #73A6D1;
        .el-input--small .el-input__inner{
            background: rgba(21,95,192,0.1) !important;
            border: 1px solid #73A6D1 !important;
            color: #B6D4FF;
            backdrop-filter: blur(4px);
        }
        //详细时间下拉选择框
        .el-time-panel{
            background: #13274b !important;
            border: 1px solid #73A6D1 !important;
            color: #B6D4FF;
           .el-time-panel__content.has-seconds{
            .el-time-spinner__item{
                color: #B6D4FF;
                &.active{
                    color: #fff;
                }
            }
            .el-time-spinner__item:hover{ //鼠标移上时间选择改变背景颜色
                background-color: #3f7dac;
            }
           }
           .el-time-panel__footer{ //时间选择底部
            .el-time-panel__btn{ //时间选择按钮
               &.cancel{
                color: #fff;
            } 
                &.confirm{
                    color: #fff;
                }
            }
            .el-time-panel__btn:hover{
                transition: all .5s;
                color: #73A6D1;
            }
           }
        }
        //头部中间箭头
        .el-icon-arrow-right{
            color: #B6D4FF;
        }
    }
    //左右箭头这一行
    .el-picker-panel__icon-btn{
        color: #fff;
    }
    .el-date-table{ //表格选择日期
        th{//日一二三四五六下面的横线
            color: #B6D4FF;
            border-bottom: 1px solid #73A6D1
        }
        td.today span {//默认当天日期的颜色
        color: #fff;
        }
        .el-date-table__row{//每一行日期
            .available{
                &.in-range{//选择日期的样式
                &>div{
                    background-color: #3f7dac;
                }
                }
            }
        }
    }
    
       
        //中间线
        .el-date-range-picker__content.is-left{
            border-right: 1px solid #73A6D1
        }
        //底部
        .el-picker-panel__footer{
            background: rgba(21,95,192,0.1) !important;
            backdrop-filter: blur(4px);
            border-top: 1px solid #73A6D1;
              //此刻btn
            .el-button--text{
                display: inline-block;
                width: 56px;
                height: 28px;
                color: white;
                background: rgba(21,95,192,0.1) !important;
                backdrop-filter: blur(4px);
                border: 1px solid #73A6D1;
            }
            .el-button--text:hover{
                transition: all .5s;
                color: #73A6D1;
            }
            //确定btn
            .el-button+.el-button{
                color: white;
                background: rgba(21,95,192,0.1) !important;
                backdrop-filter: blur(4px);
                border: 1px solid #73A6D1;
            }
            .el-button+.el-button:hover{
                &:not(.is-disabled) {//在按钮禁用时消除hover效果
                 transition: all .5s;
                color: #73A6D1;
                }
            }
        }
  }
</style>