<template>
  <div class="cpt-select">
    <div class="select_box" :class="isFind ? 'active' : ''">
      <el-tooltip
        class="item"
        effect="light"
        content="搜索"
        placement="top-start"
      >
        <!-- <span class="iconfont el-icon-search" style="color:#007aff; width:100%;height:100%" @click.stop="isFind = !isFind"></span
      > -->
        <img
          class="iconimg"
          @click.stop="isFind = !isFind"
          src="~@/assets/basic-tools/icon-search.png"
        />
      </el-tooltip>
      <el-form v-if="isFind">
        <el-form-item prop="destination">
          <div class="item-group">
            <el-autocomplete
              popper-class="search"
              :popper-append-to-body="false"
              class="autocomplete"
              v-model="destination.name"
              :fetch-suggestions="handle_query_address_async"
              placeholder="请输入目标位置"
              :trigger-on-focus="false"
              @select="handle_select_address"
              clearable
            >
              <template slot-scope="{ item }">
                <div>
                  <span style="font-size: 14px; color: #333">{{
                    item.name
                  }}</span>
                  <span
                    style="font-size: 12px; color: #999; margin-left: 12px"
                    >{{ item.address }}</span
                  >
                </div>
              </template>
            </el-autocomplete>
            <!-- <div @click="handle_pick" class="el-icon-location-outline"></div> -->
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
let handler = null;
let data_srouce = null;
let location_icon = null;

let noFlyEntities = [];
let electricFenceEntities = [];
export default {
  data() {
    return {
      isFind: false,
      input: "",
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
    };
  },
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },
    handle_select_address(item) {
      if (item) {
        let location = item.location.split(",");
        this.destination.name = item.name;
        this.destination.lng = Number(location[0]);
        this.destination.lat = Number(location[1]);

        let cesium_layer = this.g_cesium_layer();
        let viewer = window.viewer;
        // let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lng,
          lat: this.destination.lat,
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion);
      }
    },
    init_location_icon(position) {
      // let cesium_layer = this.g_cesium_layer();
      // let viewer = cesium_layer.viewer();
      let viewer = window.viewer;
      if (location_icon) {
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
      }
    },
    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },
    destroy_cesium_handler() {
      let viewer = window.viewer;
      viewer.scene.globe.depthTestAgainstTerrain = false;

      if (handler) {
        // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler && handler.destroy() && (handler = null);
        handler = null;
      }

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
    },
    clear_entities() {
      let viewer = window.viewer;
      noFlyEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      electricFenceEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      noFlyEntities = [];
      electricFenceEntities = [];
    },
  },
  mounted() {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".cpt-select"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isFind = false;
      }
    });
  },
  computed: {
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },
  destroyed() {
    this.destroy_cesium_handler();
    this.clear_entities();
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.cpt-select {
  text-align: right;
  ::v-deep.el-autocomplete-suggestion li:hover {
    background-color: #a1b9ee;
  }
  .select_box {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    background: rgba(22, 29, 45, 0.58);
    border: 1px solid #007aff;
    border-radius: 2px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    &.active {
      width: 412px;
      text-align: left;
      box-sizing: border-box;
      padding-left: 10px;
      height: 50.4px;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin-top: 15px;
    }
  }
}
.icon-sousuo {
  display: inline-block;
  width: 48px;
  height: 48px;
  cursor: pointer;
  &::before {
    font-size: 24px;
    content: "\e6d5";
    color: #00ffff;
  }
}
::v-deep .el-input__inner {
  padding-right: 30px;
  width: 359px !important;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
  background: transparent !important;
  border: 0;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
  width: 50%;
  position: absolute;
  top: 5%;
  left: 12%;
}
::v-deep input {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4 !important;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}
::v-deep {
  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }
  }
}
</style>
<style lang="scss">
.search {
  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }
  }
}
</style>
